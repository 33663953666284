import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Option } from '../any-switch/any-switch.component';
import { IntegrationService } from 'src/app/providers/integration-service/integration-service';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';


const WEEKDAYS = [
  {
    title: 'MONDAY',
    value: 'monday',
  },
  {
    title: 'TUESDAY',
    value: 'tuesday',
  },
  {
    title: 'WEDNESDAY',
    value: 'wednesday',
  },
  {
    title: 'THURSDAY',
    value: 'thursday',
  },
  {
    title: 'FRIDAY',
    value: 'friday',
  },
  {
    title: 'SATURDAY',
    value: 'saturday',
  },
  {
    title: 'SUNDAY',
    value: 'sunday',
  },
] as Option[];

@Component({
  selector: 'interval-builder',
  templateUrl: './interval-builder.component.html',
  styleUrls: ['./interval-builder.component.scss'],
})
export class IntervalBuilderComponent  implements OnInit {

  intervalOptions: Option[];
  timeOptions: Option[];
  weekdayOptions: Option[];
  dayOfMonthOptions: Option[];

  _interval: string;
  
  @Input()
  set interval(val: string) {
    this.parseIntervalString(val);
  }
  get interval(): string { return this._intervalString; }
  _intervalString: string = "";

  @Output() change = new EventEmitter<string>();

  // daily
  time: string = "10:00";

  // weekly
  dayOfWeek: string = "monday";

  // monthly
  dayOfMonth: string = "1"; // 1-31 and last

  constructor(private integrationService: IntegrationService, private translate: TranslationBridge) {
    // get interval options
    this.intervalOptions = this.integrationService.getAvailableSyncIntervals({asOptions: true});

    // get time options
    this.timeOptions = [];
    for(let i=0; i<24; i++){
      for(let j=0; j<60; j+=15){ // 15 min intervals minum
        this.timeOptions.push({
          title: i.toString().padStart(2, '0')+":"+j.toString().padStart(2, '0'),
          value: i.toString().padStart(2, '0')+":"+j.toString().padStart(2, '0'),
        } as any);
      }
    }

    // get weekday options
    this.weekdayOptions = WEEKDAYS;
    this.weekdayOptions.forEach(weekday => {
      weekday.title = this.translate.get(weekday.title);
    });

    // the available days of the months
    this.dayOfMonthOptions = [];
    for(let i=1; i<=31; i++){
      this.dayOfMonthOptions.push({
        title: i.toString()+".",
        value: i.toString(),
      } as any);
    }

    this.dayOfMonthOptions.push({
      title: this.translate.get('LAST_DAY_OF_MONTH'),
      value: 'lastDayOfMonth',
    } as any);

    this.buildIntervalString();

  }

  ngOnInit() {
    if (!this._interval) {
      this._interval = this.intervalOptions[0].value;
    }
  }

  onTimeIntervalChanged(ev: any) {
    this._interval = ev.value;
    this.buildIntervalString();
    this.change.emit(this._intervalString);
  }

  onDayOfMonthChanged(ev: any) {
    this.dayOfMonth = ev.value;
    this.buildIntervalString();
    this.change.emit(this._intervalString);
  }

  onWeekdayChanged(ev: any) {
    this.dayOfWeek = ev.value;
    this.buildIntervalString();
    this.change.emit(this._intervalString);
  }

  onTimeChanged(ev: any){
    this.time = ev.value;
    this.buildIntervalString();
    this.change.emit(this._intervalString);
  }

  buildIntervalString(): string{
    if(this._interval == "daily"){
      this._intervalString = this._interval+" at "+this.time;
    } else if(this._interval == "weekly"){
      this._intervalString = this._interval+" "+this.dayOfWeek+"s"+" at "+this.time;
    } else if(this._interval == "monthly"){
      this._intervalString = this._interval+" "+this.dayOfMonth+" at "+this.time;
    } else {
      this._intervalString = "";
    }
    return this._intervalString;
  }

  parseIntervalString(encodedString: string): void {

    // split interval string into interval and params
    let intervalParams = encodedString.split(" ");

    // set interval
    this._interval = intervalParams[0].trim();

    if(this._interval == "daily"){
      this.time = intervalParams[2].trim();
    } else if(this._interval == "weekly"){
      this.dayOfWeek = intervalParams[1].trim().substring(0, intervalParams[1].length-1); // remove trailing "s"
      // intervalParams[2] is "at"
      this.time = intervalParams[3].trim();
    } else if(this._interval == "monthly"){
      this.dayOfMonth = intervalParams[1].trim();
      // intervalParams[2] is "at"
      this.time = intervalParams[3].trim();
    }

  }

}
