import { Component, Input, OnInit, Output } from '@angular/core';
import { Institution } from 'src/app/models/Institution';
import { ConnectedInstitution } from 'src/app/models/ConnectedInstitution';
import { InstitutionService } from 'src/app/providers/institution-service/institution-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'bank-list',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.scss'],
})
export class BankListComponent implements OnInit {

  _items: Institution[] | ConnectedInstitution[];
  @Input() set items(items: any) {
    this._items = items as Institution[] | ConnectedInstitution[];
  }
  
  @Input() layout: string = '';
  @Input() actions: string = '';
  @Input() loading: boolean = false;

  @Output() onSelect = new EventEmitter<Institution | ConnectedInstitution>();

  constructor(
    private institutionService: InstitutionService, 
    private projectService: ProjectService) { }

  ngOnInit() {}

  async handleSelect(item: Institution | ConnectedInstitution) {
    this.onSelect.emit(item);
  }

  getBankLogo(item: ConnectedInstitution) {
    let url = "";
    if(item instanceof Institution || item.bank == null) {
      // @ts-ignore
      url = item.logo?.url;
    } else {
      url = item.bank.logo?.url;
    }
    if(!url) {
      url = "assets/images/bank-logo-placeholder.png";
    }
    return url;
  }


  getBankIcon(item: Institution | ConnectedInstitution) {
    let url = "";
    if(item instanceof Institution || item.bank == null) {
      // @ts-ignore
      url = item.icon?.url;
    } else {
      url = item.bank.icon?.url;
    }
    if(!url) {
      url = "assets/images/bank-icon-placeholder.png";
    }
    return url;
  }

  categoryIcon(category: string) {
    switch(category.toUpperCase()) {
      case 'CHECKING': return 'assets/icons/phos/bank-duotone.svg';
      case 'CREDITCARD': return 'assets/icons/phos/bank-duotone.svg';
      case 'SAVINGS': return 'assets/icons/phos/coin-duotone.svg';
      case 'SECURITY': return 'assets/icons/phos/briefcase-duotone.svg';
      case 'LOAN': return 'assets/icons/phos/handshake-duotone.svg';
      case 'MEMBERSHIP': return 'assets/icons/phos/briefcase-duotone.svg';

      default: return 'assets/icons/phos/cardholder-duotone.svg';
    }
  }

  getExpirationDate(item: ConnectedInstitution) {

    if(!item?.interfaces[0]) return null;
    if(!item.interfaces[0].aisConsent) return null;

    if(item.interfaces[0].aisConsent.expiresAt) return item.interfaces[0].aisConsent.expiresAt;
    return null;
  }


  isOutdated(item: ConnectedInstitution): boolean {
    return this.institutionService.isOutdatedConnection(item);
  }

}
