import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonPopover, ToastController } from '@ionic/angular';
import { Transaction } from 'src/app/models/Transaction';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { TransactionService } from 'src/app/providers/transaction-service/transaction-service';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';

const AVAILABLE_FIELDS = [
  'amount',
  'purpose',
  'bankBookingDate',
  'counterpartName',
  'counterpartIban',
  'category',
];

@Component({
  selector: 'transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent  implements OnInit {

  @ViewChild('popover') popover: IonPopover;
  isContextMenuOpen: boolean = false;

  contextMenuTransaction: Transaction = null;
  contextMenuField: string = '';

  _transactions: Transaction[] = [];
  @Input() set transactions(transactions: Transaction[]) {
    this._transactions = transactions;
  }
  get transactions() {
    return this._transactions;
  }

  @Input() enableFilterBar: boolean = false;
  _filter: string = '';
  @Input() set filter(filter: string) {
    this._filter = filter;
  }
  get filter() {
    return this._filter;
  }
  
  _fields: string[] = [];
  @Input() set fields(fields: string[]) {
    this._fields = fields;
  }
  get fields() {
    return this._fields;
  }

  _categoryOptions = [];

  _fieldWidths: {[key: string]: string} = {};
  _allFieldWidthsSum: number = 800;

  private resizing:boolean = false;
  private currentField: string = '';
  private mouseDownX: number = 0;
  private resizingCellStartWidth: number = 0;

  constructor(
    private translate: TranslationBridge,
    private toastController: ToastController,
    private transactionService: TransactionService,
    private projectService: ProjectService
    ) { }

  ngOnInit() {

    if(!this.fields || this.fields.length === 0) {
      this.fields = [
        'amount',
        'purpose',
        'bankBookingDate',
        'counterpartName',
        'counterpartIban',
        'category',
      ];
    }

    // default field widths
    this._fieldWidths = AVAILABLE_FIELDS.reduce((acc, field) => {
      acc[field] = this.getFieldWidth(field);
      return acc;
    }, {});
    this.recalculateAllFieldWidthsSum();
  }

  ngAfterViewInit() {
  
    let projectId = this.projectService.getCurrentProjectId();
    this.transactionService.getCategoriesAsOptions(projectId).then((categories) => {
      this._categoryOptions = categories;
    });
  }

  getFieldTranslation(field: string) {
    const key = field.replace(/([A-Z])/g, '_$1').toUpperCase();
    return this.translate.get(`TRANSACTION_FIELD_${key}`);
  }

  getFieldWidth(field: string) {
    if(field === 'amount') {
      return '100px';
    }
    if(field === 'valueDate') {
      return '160px';
    }
    if(field === 'counterpartName') {
      return '220px';
    }
    if(field === 'counterpartIban') {
      return '220px';
    }
    if(field === 'category') {
      return '180px';
    }
    if(field === 'purpose') {
      return '360px';
    }
    return '220px';
  }

  recalculateAllFieldWidthsSum() {

    // calculate the sum of all field widths but only of the visible fields (.fields)
    this._allFieldWidthsSum = Object.keys(this._fieldWidths).reduce((acc, field) => {
      if(this.fields.includes(field)) {
        acc += parseInt(this._fieldWidths[field])+20; // +20 for the padding
      }
      return acc;
    }, 0);
  }

  startColumnResize(event: MouseEvent, field: string) {
    this.resizing = true;
    this.currentField = field;
    this.mouseDownX = event.pageX;
    this.resizingCellStartWidth = parseInt(this._fieldWidths[field]);
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.stopColumnResize);
  }

  private onMouseMove = (event: MouseEvent) => {
    if (this.resizing) {
      const newWidth = event.pageX - this.mouseDownX + this.resizingCellStartWidth;
      
      if(newWidth < 20 || newWidth > 680) {
        return;
      }
      
      this._fieldWidths[this.currentField] = `${newWidth}px`;
      this.recalculateAllFieldWidthsSum();
    }
  }

  stopColumnResize = () => {
    this.resizing = false;
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.stopColumnResize);
  }

  onContextClick(event: MouseEvent, transaction: any, field: string) {
    event.preventDefault();
    this.isContextMenuOpen = true;
    this.contextMenuTransaction = transaction;
    this.contextMenuField = field;
    this.popover.event = event;
  }

  copyCell(field: string, transaction: Transaction) {
    this.isContextMenuOpen = false;
    this.copyValue(transaction[field]);
  }

  copyTransaction(transaction: Transaction) {
    
    this.isContextMenuOpen = false;

    const flatTransaction = this.transactionService.flattenTransaction(transaction);

    // filter out the fields that are not in the fields array
    const filteredTransaction = Object.keys(flatTransaction).filter(field => this.fields.includes(field)).reduce((acc, field) => {
      acc[field] = flatTransaction[field];
      return acc;
    }, {});

    // also keep the order of the fields
    const orderedTransaction = this.fields.reduce((acc, field) => {
      acc[field] = filteredTransaction[field];
      return acc;
    }, {});


    const excelData = Object.values(orderedTransaction).map(value => value.toString()).join("\t");
    this.copyValue(excelData);
  }


  copyValue(value: any){
    navigator.clipboard.writeText(value).then(() => {
      this.toastController.create({
        message: 'Copied to clipboard!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t copy to clipboard.',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    });
  }



}
