import { Injectable, Inject } from '@angular/core';
import { TranslationBridge } from '../translation-bridge/translation-bridge';
import { ProjectService } from '../project-service/project-service';
import { APIKey } from 'src/app/models/APIKey';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth-service/auth-service';

const API_KEY_SCOPES = ['ALL', 'ACCOUNTS', 'TRANSACTIONS', 'WORKSPACE'];

@Injectable({
    providedIn: 'root'
})
export class APIService {

  constructor(
    private projectService: ProjectService,
    private translate: TranslationBridge,
    private http: HttpClient,
    private authService: AuthService
  ){

  }

  public addKey(apiKey: APIKey): Promise<APIKey>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      if(!project.apiKeys) project.apiKeys = [];

      // generate id if missing 
      if(!apiKey.id) apiKey.id = new Date().getTime().toString();

      // some defaults
      if(!apiKey.active) apiKey.active = false;
      if(!apiKey.createdAt) apiKey.createdAt = new Date();
      if(!apiKey.expiresAt) apiKey.expiresAt = null;
      if(!apiKey.lastUsedAt) apiKey.lastUsedAt = null;
      if(!apiKey.scopes) apiKey.scopes = ['ALL'];
      if(!apiKey.accounts) apiKey.accounts = [''];
      if(!apiKey.allowedIPs) apiKey.allowedIPs = [];
      if(!apiKey.createdBy) apiKey.createdBy = this.authService.currentUser.uid;

      if(apiKey.key && apiKey.key.length > 19){
        const start = apiKey.key.substring(0, 8);
        const end = apiKey.key.substring(apiKey.key.length - 8);
        apiKey.key = start + '...' + end;
      }
      
      // put it to the beginning of apiKeys
      if(!project.apiKeys) project.apiKeys = [];
      project.apiKeys.unshift(apiKey);

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(apiKey);
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  public updateKey(apiKey: APIKey): Promise<APIKey>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      if(!project.apiKeys) return reject("No API keys found");

      let index = project.apiKeys.findIndex((int) => {
        return int.id == apiKey.id;
      });

      if(index == -1) return reject("Integration not found");

      if(apiKey.key && apiKey.key.length > 19){
        const start = apiKey.key.substring(0, 8);
        const end = apiKey.key.substring(apiKey.key.length - 8);
        apiKey.key = start + '...' + end;
      }

      project.apiKeys[index] = apiKey;

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(project.apiKeys[index]);
      }).catch((err) => {
        return reject(err);
      });   
    });
  }

  public deleteKey(apiKeyId: string): Promise<boolean>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      if(!project.apiKeys) return reject("No API keys found");

      let index = project.apiKeys.findIndex((int) => {
        return int.id == apiKeyId;
      });

      if(index == -1) return reject("Integration not found");

      project.apiKeys.splice(index,1);

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });   
    });
  }

  public generateAPISecret(apiKeyId: string): Promise<any>{
    
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      let index = project.apiKeys.findIndex((int) => {
        return int.id == apiKeyId;
      });

      if(index == -1) return reject("API Key not found");

      let token = await this.authService.getAuthToken();
      let headers = {
        'Authorization': token
      };

      let readRes = await this.http.post(environment.systemAPI + '/' + project.id + '/apikey/' + apiKeyId + '', {}, { headers: headers }).toPromise() as any;
      return resolve(readRes.key);
    });
  
  }

  public activateKey(apiKeyId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      let index = project.apiKeys.findIndex((int) => {
        return int.id == apiKeyId;
      });

      if(index == -1) return reject("API Key not found");

      project.apiKeys[index].active = true;

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  public deactivateKey(apiKeyId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      let index = project.apiKeys.findIndex((int) => {
        return int.id == apiKeyId;
      });

      if(index == -1) return reject("API Key not found");

      project.apiKeys[index].active = false;

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  public getAPISecret(apiKeyId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
    let project = this.projectService.getCurrentProject();
    if(!project) return reject("No active project found");
    let index = project.apiKeys.findIndex((int) => {
      return int.id == apiKeyId;
    });

    if(index == -1) return reject("API Key not found");

        let token = await this.authService.getAuthToken();
        let headers = {
          'Authorization': token
        };
  
        let readRes = await this.http.get(environment.systemAPI + '/' + project.id + '/apikey/' + apiKeyId + '', { headers: headers }).toPromise() as any;
        return resolve(readRes.key);
    });
  }

  public getScopes(opts: any = {}): Array<any>{
    if(!opts || !opts.asOptions) return API_KEY_SCOPES;

    let scopes = API_KEY_SCOPES.map(scope => {
      return {
        value: scope,
        title: this.translate.get("API_KEY_SCOPE_" + scope.toUpperCase().trim()),
        subtitle: this.translate.get("API_KEY_SCOPE_" + scope.toUpperCase().trim() + "_INFO"),
        clearOthers: (scope == 'ALL' ? true : false),
      }
    });
    return scopes;
  }

}
