import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';


import { User } from '../../models/User';
import { environment } from './../../../environments/environment';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  fireUser: any;
  currentUser: User;

  constructor(
      private http: HttpClient,
      public afs: AngularFirestore,
      public afAuth: AngularFireAuth,
      private afStorage: AngularFireStorage
    ){
      this.reviveFromCache().then(()=>{
        
      }).catch((e)=>{
      });
  }

  // Sign in with email/password
  public emailLogin(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.setUserData(result);
        resolve(this.currentUser);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // signup with email/password
  public emailSignup(email: string, password: string, displayName: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.setUserData(result);
        this.updateUserInformation(displayName,null).then(()=>{
          // might be okay. might fail, but should not prevent the signup.
        });
        resolve(this.currentUser);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /*
   Send email verfificaiton when new user sign up
  */
  public sendVerificationMail() {
   return new Promise<any>(async (resolve, reject) => {
      (await this.afAuth.currentUser).sendEmailVerification()
      .then(() => {
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /*
    Reset Forggot password
  */
  public forgotPassword(passwordResetEmail: string) {
    return new Promise<any>((resolve, reject) => {
     this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  private setUserData(fireUser = null): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      let user = this.currentUser;
      if(fireUser){
        this.fireUser = fireUser;
        user = fireUser.user;
      }

      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

      let userData: User = {
        uid: user.uid,
        created: (user.created ? user.created : new Date()),
        lastLogin: new Date(),
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified,
        preferences: user.preferences,
        activated: user.activated
      };

      userData = JSON.parse(JSON.stringify(userData));
      return resolve(userRef.set(userData, {
        merge: true
      }));
    });
  }
  public updateUserMail(newMail){
    return new Promise(async (resolve, reject) => {
      (await this.afAuth.currentUser).updateEmail(newMail).then((res)=>{
        this.currentUser.email = newMail;
        this.setUserData();
        // @ts-ignore
        resolve(res);
      }).catch((err) =>{
        reject(err);
      });
    });
  }

  public updateUserPassword(newPassword){
    return new Promise(async (resolve, reject) => {
      (await this.afAuth.currentUser).updatePassword(newPassword).then((res) =>{
        // @ts-ignore
        resolve(res);
      }).catch( (err) => {
        reject(err);
      });
    });
  }

  public updateUserInformation(name,photo){
    return new Promise(async (resolve, reject) => {
      (await this.afAuth.currentUser).updateProfile({
        displayName: name,
        photoURL: photo
      }).then((res)=>{
        this.currentUser.displayName = name;
        this.currentUser.photoURL = photo;
        this.setUserData();
        // @ts-ignore
        resolve(res);
      }).catch((err) =>{
        reject(err);
      });
    });
  }

  // Returns true when user is looged in
  public isLoggedIn(): boolean {
    return (this.currentUser) ? true : false;
  }

  public isAnon(): boolean {
    return (!this.currentUser.email) ? true : false;
  }

  public getUid(): Promise<string> {
    return new Promise((resolve, reject) => {
      if(!this.currentUser){
        this.reviveFromCache().then(()=>{
          return resolve(this.currentUser.uid);
        }).catch((e)=>{});
      } else {
          return resolve(this.currentUser.uid);
      }
    });
  }

  public reviveFromCache(){
    return new Promise((resolve, reject) => {
      /* Saving user data in localstorage when
        logged in and setting up null when logged out */
      this.afAuth.authState.subscribe(user => {
          if (user) {
              this.fireUser = user;
              const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
              userRef.ref.get().then((doc)=>{
                // @ts-ignore
                this.currentUser = doc.data();

                // in case of emailVerified the fireauth user is more relevant
                if(!this.currentUser.emailVerified){
                  this.currentUser.emailVerified = this.fireUser.emailVerified;
                }
                //this.getAccountStatus().then(()=>{
                  resolve(this.currentUser);
                //});
              });
              // todo native storage fallback?
          } else {
            reject('session failed');
          }
      });
    });
  }
  /*
    get the ID Token for auth with API Sever (firebase ID Token)
  */
  public getAuthToken() : Promise<string> {
  	if(!this.currentUser) return new Promise((resolve,reject) => { resolve('');	});
  	return this.fireUser.getIdToken().then((token) => {
      return 'Bearer '+token;
    }).catch((e) => {
      console.error('AUTH-SERVICE: session probably died');
    });
  }

  /*
    check if session is loaded
  */
  public isReady(){
    return new Promise((resolve, reject) => {
      this.getUid().then( (uid) => {
        return resolve(uid);
      });
    });
  }


  /*
    logout
  */
  public logout(){
     return new Promise((resolve, reject) => {
       this.afAuth.signOut().then(() => {
         this.currentUser = null;
         resolve(null);
       }).catch(()=>{
         reject();
       });
     });
  }

  /*
    Delete everyting the user owns
  */
  public deleteCurrentUser(){
     return new Promise(async (resolve, reject) => {
       const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${this.currentUser.uid}`);
       userRef.ref.delete();
      (await this.afAuth.currentUser).delete();
      resolve(true);
     });
  }

  /*
    User Preferences
  */
  public getOption(option){
    if(!this.currentUser || !this.currentUser.preferences) return null;
    return this.currentUser.preferences[option];
  }
  public setOption(option,value){
    new Promise((resolve,reject)=>{
      if(!this.currentUser.preferences) this.currentUser.preferences = {};
      this.currentUser.preferences[option] = value;
      this.setUserData().then(()=>{
        resolve(true);
      })
    });
  }

  /*
    avatar upload
  */
  public uploadAvatar(fileEvent){
    return new Promise(async (resolve,reject)=>{
      const randomId = Math.random().toString(36).substring(2);

       let fileEx = fileEvent.target.files[0].type;

       if(fileEx == "image/jpeg"){
         fileEx = ".jpg";
       } else if(fileEx == "image/png"){
         fileEx = ".png";
       } else {
         return reject("File type not allowed");
       }

       const filePath = 'avatars/'+this.currentUser.uid+'/'+randomId+fileEx;

       const storageRef = this.afStorage.ref(filePath);
       const uploadTask = this.afStorage.upload(filePath, fileEvent.target.files[0]);

       

       uploadTask.snapshotChanges().pipe(
         finalize(() => {
           storageRef.getDownloadURL().subscribe(async (downloadURL) => {
             let url = downloadURL.replace('.jpg','_200x200.jpeg').replace('.png','_200x200.jpeg').replace('.gif','_200x200.jpeg');

             // set cache control
             const ref = this.afStorage.ref(filePath);
             ref.updateMetadata({ cacheControl: 'private, max-age=3600' });

             (await this.afAuth.currentUser).updateProfile({
               photoURL: url
             }).then((res)=>{
               // @ts-ignore
               resolve(url);
             }).catch((err) =>{
               reject(err);
             });

           });
         })
       ).subscribe();
     });
     //return uploadTask.percentageChanges();
  }

}
