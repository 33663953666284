import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/models/Project';

@Component({
  selector: 'subscription-clock',
  templateUrl: './subscription-clock.component.html',
  styleUrls: ['./subscription-clock.component.scss'],
})
export class SubscriptionClockComponent  implements OnInit {

  isSubscribed: boolean = false;
  isActive: boolean = false;
  isExpired: boolean = false;
  isCanceled: boolean = false;
  isExclusive: boolean = false;

  status: string = "active";

  endDate: Date = null;
  periodDate: Date = null;

  _project: Project;
  @Input() set project(project: Project){
    this._project = project;
    
    if(!project){
      this.isSubscribed = false;
      this.isActive = false;
      this.isExpired = false;
      this.isCanceled = false;
      this.endDate = null;
      return;
    }

    if(project?.isExclusive){
      this.isExclusive = true;
      return;
    }

    this.status = project.status;
    this.isSubscribed = project.isSubscribed;
    this.isActive = project.status == "active";
    this.isCanceled = project.isCanceled;

    this.endDate = project.trialEnd;
    if(this.isActive){
      this.periodDate = project.subscriptionPeriodEnd;
      this.endDate = project.subscriptionCancelAt;
    }
    if(project.subscriptionCancelAt){
      this.endDate = project.subscriptionCancelAt;
    }

  }
  get project(): Project {
    return this._project;
  }

  constructor() { }

  ngOnInit() {}


  /*
    date is in past
  */
    public isInPast(date: Date){
      return (new Date(date).getTime() < new Date().getTime());
    }
  

}
