export class Institution {
  id: number;
  name: string;
  logo: { url: string };
  icon: { url: string };
  location: string;
  city: string;
  bic: string;
  blz: string;
}
