import { Component, OnInit, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'link-copy-box',
  templateUrl: './link-copy-box.component.html',
  styleUrls: ['./link-copy-box.component.scss'],
})
export class LinkCopyBoxComponent implements OnInit {

  _href: string;
  @Input() set href(value: string) {
    if(this.prefixAPI && value && value.indexOf('http') != 0){
      this._href  = environment.systemAPI + value;
    } else if(this.prefixDomain && !this.prefixAPI && value && value.indexOf('http') != 0){
      this._href  = 'https://'+environment.productBackend + value;
    } else {
      this._href = value;
    }
  }
  get href(): string {
    return this._href;
  }
  
  @Input() prefixDomain: boolean = true;
  @Input() prefixAPI: boolean = false;
  
  @Input() disabled: boolean = false;
  @Input() disabledMessage: string = "";

  @Input() emptyMessage: string = "";

  @Input() method: string = '';

  @Input() showCopy: boolean = true;
  @Input() showOpenNewTab: boolean = false;

  @Input() truncateStart: number = 0;
  @Input() truncateEnd: number = 0;

  constructor(private toastController: ToastController) {}

  ngOnInit() {}

  onLinkClick(ev: any){

    if(ev.target.classList.contains('open-tab')){
      ev.stopPropagation();
      return;
    }

    ev.preventDefault();

    if(!this.showCopy){
      return;
    }

    ev.stopPropagation();

    if(this.disabled){
      if(this.disabledMessage) this.toastController.create({
        message: this.disabledMessage,
        duration: 2000,
        cssClass: 'toast-error',
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
      return;
    }

    navigator.clipboard.writeText(this._href).then(() => {
      this.toastController.create({
        message: 'Link copied to clipboard!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t copy link to clipboard.',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
      console.log('Could not copy text: ', err);
    });

  }

}
