import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'subscription-label',
  templateUrl: './subscription-label.component.html',
  styleUrls: ['./subscription-label.component.scss'],
})
export class SubscriptionLabelComponent  implements OnInit {

  loading: boolean = false;
  _tier: string = '';
  @Input()
  set tier(val: string) {
    if(val) {
      this._tier = val;
      this.loading = false;
      this.class = val.toLowerCase().replace('&','-and-');
    } else {
      this.loading = true;
    }
  }
  get tier(): string { return this.tier; }
  @HostBinding('class') class = 'trial';

  constructor() { }

  ngOnInit() {}

}
