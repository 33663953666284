export class Integration {
    id: string;
    name: string;
    type: string;
    setupStep: string;
    config: any;
    secrets: any;
    active: boolean;
    interval: string;
    lastSync?: Date;
    nextSync?: Date;
    syncStatus?: string;
    syncToken?: string;
    syncLog?: {
        date: Date;
        items: number;
        status: string;
        logs: any[];
    };
}