import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Integration } from 'src/app/models/Integration';
import { IntegrationService } from 'src/app/providers/integration-service/integration-service';

interface IntegrationListItem {
  name: string;
  icon: string;
  type: string;
  description: string;
};

@Component({
  selector: 'integration-list',
  templateUrl: './integration-list.component.html',
  styleUrls: ['./integration-list.component.scss'],
})
export class IntegrationListComponent implements OnInit {

  _integrations: any = [];
  @Input('integrations') set integrations(integrations: any){
   this._integrations = integrations;
  }
  
  @Input() emptySlots: number = 0;

  @Output() itemClick = new EventEmitter<any>();

  constructor(public integrationService: IntegrationService) { }

  ngOnInit() {}

  pickImage(str: string){
    if(str.indexOf('icon') == 0){
      return 'assets/icons/phos/' + str.replace('icon:', '');
    } else if(str.indexOf('image') == 0){
      return 'assets/integrations/' + str.replace('image:', '');
    } else {
      return str;
    }
  }

  getIcon(integration: any){
    let icon = integration.icon;
    if(!integration.icon){
      icon = this.integrationService.getIntegrationIcon(integration.type);
    }
    if(icon.indexOf('icon') == 0){
      return 'assets/icons/phos/' + icon.replace('icon:', '');
    } else if(icon.indexOf('image') == 0){
      return 'assets/integrations/' + icon.replace('image:', '');
    } else {
      return icon;
    }
  }

  onItemClicked(integration: IntegrationListItem){
    this.itemClick.emit(integration);
  }

  createNewItemClick(){
    this.itemClick.emit({type: 'new'});
  }
}
