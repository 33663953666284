import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { PopoverController, ModalController } from '@ionic/angular';

import { Project } from '../../models/Project';
import { ProjectService } from '../../providers/project-service/project-service';
import { CreateProjectPage } from '../../pages/create-project/create-project.page';

import { Observable } from 'rxjs';

@Component({
  selector: 'project-switch-popover',
  templateUrl: './project-switch-popover.component.html',
  styleUrls: ['./project-switch-popover.component.scss'],
})
export class ProjectSwitchPopoverComponent implements OnInit {

  projects: Observable<Array<Project>>;
  @Input() creation: boolean = true;

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController,
    public projectService: ProjectService,
    public navCtrl: NavController,) {}

  async ngOnInit() {
    this.projects = this.projectService.getProjects();
    console.log(await this.projectService.getProjectsProm());
  }

  async createProject(){
    this.popoverController.dismiss();
    const modal = await this.modalController.create({
      component: CreateProjectPage,
      cssClass: 'small-modal'
    });
    await modal.present();
  }

  switchProject(project: Project){
    this.navCtrl.navigateRoot('/'+project.id+'/dashboard', {
      animated: false
    });
    this.projectService.setCurrentProject(project.id);
    this.popoverController.dismiss();
  }

}
