import { Input, Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

import { ProjectMember } from 'src/app/models/ProjectMember';
import { AuthService } from 'src/app/providers/auth-service/auth-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'member-item',
  templateUrl: './member-item.component.html',
  styleUrls: ['./member-item.component.scss'],
})
export class MemberItemComponent implements OnInit {

  @Input() user: ProjectMember;
  @ViewChild('popover') popover;

  @Output() reload = new EventEmitter<boolean>();

  popoverOpen: boolean = false;

  constructor(private element: ElementRef, 
        private projectService: ProjectService, 
        private auth: AuthService, 
        private toastController: ToastController,
        private alertController: AlertController) { }

  ngOnInit() {}

  presentPopover(e: Event) {
    this.popover.event = {
      target: this.element.nativeElement.querySelector('.popover-anchor'),
    };
    this.popoverOpen = true;
  }

  currentUserIsAdmin(){
    let currentUser = this.auth.currentUser.uid;
    let project = this.projectService.getCurrentProject();
    if(project && currentUser){
      return project.owner.includes(currentUser);
    }
  }

  async openEditModal(){
  
  }

  async removeSelf(){
    this.removeUser();
  }

  async removeUser(){

    let confirm = await this.alertController.create({
      header: 'Remove Member',
      message: 'Are you sure you want to remove this member from the workspace?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.popoverOpen = false;
          }
        },
        {
          text: 'Remove',
          handler: async () => {


            this.popoverOpen = false;

            let currentUser = this.auth.currentUser.uid;
            let project = this.projectService.getCurrentProject();     
            if(project && currentUser){
              // @ts-ignore
              if(this.user.code){
                // @ts-ignore
                await this.projectService.deleteInvite(project,this.user.code);
              } else {
                await this.projectService.removeMember(project, this.user.uid);
              }
        
              this.toastController.create({
                message: 'Successfully removed member.',
                duration: 5000,
                cssClass: 'toast-success',
                position: 'top',
                buttons: [
                  {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => {
                    }
                  }
                ]
              }).then(toast => {
                toast.present();
              });      
        
              this.reload.emit(true);
              confirm.dismiss();

            }

          }
        }
      ]
    }).then((alert) => {
      alert.present();
      return alert;
    });
  }

  public resendInvite(ev: any) {
    this.popoverOpen = false;
    let project = this.projectService.getCurrentProject();     
    // @ts-ignore
    this.projectService.resendInvite(project, this.user.code).then((result) => {
       // provide message
       this.toastController.create({
        message: "Einladung wurde versendet.",
        duration: 5000,
        position: 'top',
        cssClass: 'toast-success',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => {
            }
          }
        ]

      }).then((toast) => {
        toast.present();
      });
    }).catch((error) => {
      this.toastController.create({
        message: (error.error.message ? error.error.message : error),
        duration: 5000,
        position: 'top',
        cssClass: 'toast-error',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      }).then((toast) => {
        toast.present();
      });
    });
  }

  public copyInvitationLink(ev: any) {
    this.popoverOpen = false;

    let project = this.projectService.getCurrentProject();     

    // @ts-ignore
    let url = 'https://'+environment.productBackend + '/invites/' + project.id + '/' + this.user.code + '/accept';

    navigator.clipboard.writeText(url).then(() => {
      this.toastController.create({
        message: 'Link copied to clipboard!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t copy link to clipboard.',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
      console.log('Could not copy text: ', err);
    });

  }

}
