import { Component, OnInit, ElementRef, ViewChild, Renderer2, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

import { Project } from '../../models/Project';
import { ProjectService } from '../../providers/project-service/project-service';
import { AuthService } from '../../providers/auth-service/auth-service';

import { Institution } from 'src/app/models/Institution';

import { InstitutionService } from 'src/app/providers/institution-service/institution-service';

import { load, unload } from "@finapi/web-form";

import { environment } from 'src/environments/environment';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';


@Component({
  selector: 'app-connect-bank-account',
  templateUrl: './connect-bank-account.page.html',
  styleUrls: ['./connect-bank-account.page.scss'],
  host: {
    '[class]': 'connectionStep'
  }
})
export class ConnectBankAccountPage implements OnInit {

  loading: boolean = false;

  connectionStep: string = "search";
  searchLoading: boolean = false;
  searchTerm: string = "";
  institutionsSearch: Institution[] = [];
  selectedInstitution: Institution = null;

  @Input() mode: string = "new"; // new or update

  _webformId: string = "";
  @Input() set webformId(val: string) {
    this._webformId = val;
    if (val != "") {
      this.mode = "update";
      this.connectionStep = "webform";
      if (this.embedWebForm) {
        setTimeout(() => { // let it render first
          this.createWebForm();
        }, 1000);
      } else {

      }
    }
  }
  get webformId() {
    return this._webformId;
  }

  @Input() connectionId: number = null;

  embedWebForm: boolean = environment.finAPIEmbedding;
  @ViewChild('webFormContainer') webformContainer: any;


  constructor(
    private modalCtrl: ModalController,
    private projectService: ProjectService,
    private auth: AuthService,
    public navCtrl: NavController,
    private translate: TranslationBridge,
    private institutionService: InstitutionService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private http: HttpClient,
    private location: Location,
    private renderer: Renderer2

  ) { }

  ngOnInit() {

  }

  dismiss(state?: any) {
    this.modalCtrl.dismiss(state);
    this.destroyWebform();
  }


  handleBankSearch(event) {
    let val = event.target.value;
    this.searchTerm = val;
    if (val.length > 2) {
      this.searchLoading = true;
      this.institutionService.searchInstitution(val).then((result) => {
        this.institutionsSearch = result.banks;
        this.searchLoading = false;
      }).catch((err) => {
        this.searchLoading = false;
      });
    }
  }

  async handleBankConnect(item: Institution) {
    let projectId = this.projectService.getCurrentProject().id;
    let institutionId = item.id;
    this.selectedInstitution = item;

    // @ts-ignore
    if (typeof item.bank != 'undefined') {
      return false;
    }

    this.connectionStep = "webform";

    let res = await this.institutionService.createImportRequest(institutionId, projectId);

    if (res.id) {
      this.webformId = res.id;
      if (this.embedWebForm) {
        this.createWebForm();
      } else {

      }
    }

  }

  /*
     EMBEDDED WEBFORM
   */
  createWebForm() {
    return new Promise(async (resolve, reject) => {
      let target = this.webformContainer.nativeElement;
      if (!target) return;

      let webformCSS = await this.http.get('/assets/webform.css', { responseType: 'text' }).toPromise();

      load(
        target,
        {
          token: this.webformId,
          targetUrl: environment.finapiWebformBase,
          layoutConfig: "xs",
        },
        {
          onComplete: () => {
            this.dismiss({ success: true});
          },
          onFail: () => {
          },
          onAbort: () => {
            this.dismiss({ success: false});
          },
          onLoadError: () => {
            reject({ error: "loadError" });
          },
          onLoaded: () => {
            const style = this.renderer.createElement('style');
            style.innerText = webformCSS;
            this.renderer.appendChild(this.webformContainer.nativeElement.getElementsByTagName('finapi-webform2-webcomponent')[0].shadowRoot, style);
            resolve(true);
          }
        }
      );

    });

  }

  destroyWebform(){
    this.webformId = "";      
    let target = this.webformContainer?.nativeElement;
    if (!target) return;
    unload(this.webformContainer.nativeElement);
  }


}
