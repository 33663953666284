import { Component, Input, OnInit, Output } from '@angular/core';
import { Institution } from 'src/app/models/Institution';
import { ConnectedInstitution } from 'src/app/models/ConnectedInstitution';
import { InstitutionService } from 'src/app/providers/institution-service/institution-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'bank-list-full',
  templateUrl: './bank-list-full.component.html',
  styleUrls: ['./bank-list-full.component.scss'],
})
export class BankListFullComponent implements OnInit {

  _items: Institution[] | ConnectedInstitution[];
  @Input() set items(items: any) {
    this._items = items as Institution[] | ConnectedInstitution[];
  }

  @Output() onSelect = new EventEmitter<Institution | ConnectedInstitution>();

  constructor(
    private institutionService: InstitutionService, 
    private projectService: ProjectService) { }

  ngOnInit() {}

  async handleSelect(item: Institution | ConnectedInstitution) {
    this.onSelect.emit(item);
  }

  getBankIcon(item: Institution | ConnectedInstitution) {
    let url = "";
    if(item instanceof Institution || item.bank == null) {
      // @ts-ignore
      url = item.icon?.url;
    } else {
      url = item.bank.icon?.url;
    }
    if(!url) {
      url = "assets/images/bank-icon-placeholder.png";
    }
    return url;
  }

  getExpirationDate(item: ConnectedInstitution) {

    if(!item?.interfaces[0]) return null;
    if(!item.interfaces[0].aisConsent) return null;

    if(item.interfaces[0].aisConsent.expiresAt) return item.interfaces[0].aisConsent.expiresAt;
    return null;
  }

}
