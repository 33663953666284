import { Component, OnInit, Input } from '@angular/core';

export interface NotificationMessage {
  type: string;
  icon: string;
  title: string;
  text: string;
  actionTitle: string;
  action: any;
}

@Component({
  selector: 'notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent  implements OnInit {

  @Input() messages: NotificationMessage[] = [];

  constructor() { }

  ngOnInit() {}

  executeMessageAction(message: NotificationMessage){
    if(message.action){
      message.action();
    }
  }

} 
