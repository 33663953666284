import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { IonMenu, NavController, ToastController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';

import { Project } from '../../models/Project';

import { ProjectService } from '../../providers/project-service/project-service';
import { AuthService } from '../../providers/auth-service/auth-service';
import { NotificationService } from '../../providers/notification-service/notification-service';

import { ProfilePopoverComponent } from '../profile-popover/profile-popover.component';

import { environment } from '../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';

@Component({
  selector: 'split-page',
  templateUrl: './split-page.component.html',
  styleUrls: ['./split-page.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class SplitPageComponent implements OnInit {

  @ViewChild("profileButton", {static: true}) profileButton: ElementRef;
  @ViewChild("navMenu") navMenu: IonMenu;
  menuFullyOpened: boolean = false;

  @Input() title: string;

  @Input() activeElements: string = "all";

  version: string = environment.version;

  currentSensitiveFilter: string[] = [];

  constructor(public projectService: ProjectService,
    public notificationService: NotificationService,
    public navCtrl: NavController,
    public popoverController: PopoverController,
    public auth: AuthService,
    private app: AppComponent,
    private toastController: ToastController,
    private translate: TranslationBridge
    ) { }

  ngOnInit() {
    
  }

  navigate(path: string, event: any){
    if(this.projectService.currentProject?.id && path.indexOf(':projectId') != -1){
      this.navCtrl.navigateForward(path.replace(':projectId', this.projectService.currentProject?.id), {
        animated: false
      });
    } else {
      this.navCtrl.navigateForward('/'+path, {
        animated: false
      });
    }
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  openMenu(){
    this.navMenu.toggle();
  }
  changedSplitView(ev: any){
    this.menuFullyOpened = ev?.detail?.visible;
  }

  toggleSensitiveFilter(ev){

    ev.stopPropagation();
    ev.preventDefault();

    this.app.toggleSensitive();

    this.currentSensitiveFilter = this.app.getCurrentSensitiveFilter();

    let mes = this.translate.get('SENSITIVE_FILTER_ACTIVE_NONE');
    if(this.currentSensitiveFilter.length > 0){
      mes = this.translate.get('SENSITIVE_FILTER_ACTIVE_'+this.currentSensitiveFilter[0].toUpperCase());
    }
    this.toastController.create({
      message: mes,
      position: "top",
      duration: 2000
    }).then((toast) => {
      toast.present();
    });

    return false;
  }

  async presentPopover(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();

    let fakeEv = JSON.parse(JSON.stringify(ev));
    // @ts-ignore
    fakeEv.target = this.profileButton.el;

    const popover = await this.popoverController.create({
      component: ProfilePopoverComponent,
      event: fakeEv,
      translucent: true
    });
    await popover.present();
    return false;
  }

}
