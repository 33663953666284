import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Integration } from 'src/app/models/Integration';
import { IntegrationService } from 'src/app/providers/integration-service/integration-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';

const NINOX_API_ROOT = "https://api.ninoxdb.de/v1";

@Component({
  selector: 'ninox-integration-form',
  templateUrl: './ninox.component.html',
  styleUrls: ['./ninox.component.scss'],
})
export class NinoxIntegrationFormComponent implements OnInit {

  _integration: Integration;
  @Input() set integration(integration: Integration){
    this._integration = integration;

    this.nxTeam = this._integration.config.team;
    this.nxDatabase = this._integration.config.database;
    this.nxCustomAPIBaseURL = this._integration.config.customApiUrl.replace(/\/$/, '');


    this.nxTableTransactions = this._integration.config.tableTransactions;
    this.nxTableBankAccounts = this._integration.config.tableAccounts;
    this.nxTableCounterparts = this._integration.config.tableCounterparts;

    this.nxTableTransactionsMatching = this._integration.config.fieldMappingTransactions || {};
    this.nxTableBankAccountsMatching = this._integration.config.fieldMappingAccounts || {};
    this.nxTableCounterpartsMatching = this._integration.config.fieldMappingCounterparts || {};

    if(this._integration.setupStep == "setup"){
      // start with the key
      this._integration.setupStep = "setupKey";
    }

    this.interval = this._integration.interval;

    if(this._integration.setupStep != "completed") this.loadConfig();

  }

  @Output() onDismiss = new EventEmitter<boolean>();

  apiKey: string = "";
  apiKeyValid: boolean = false;
  apiKeyLoading: boolean = false;
  apiKeyDidLoad: boolean = false;
  showAPIKey: boolean = false;

  loading: boolean = false;
  stale: boolean = false;

  // nx connection info
  nxTeamsOptions: any = [];
  nxTeam: string = "";
  nxDatabasesOptions: any = [];
  nxDatabase: string = "";
  nxCustomAPIBaseURL: string = "";
  nxLoading: boolean = false;

  // available tables
  nxTablesOptions: any = [];

  // table names
  nxTableTransactions: string = ""; // Transaktionen
  nxTableBankAccounts: string = ""; // Eigene Bankkonten
  nxTableCounterparts: string = ""; // Counterpart Bankkonten

  // mapping is loading
  mappingLoading: boolean = true;

  // available fields
  nxTableTransactionsFields: any = [];
  nxTableBankAccountsFields: any = [];
  nxTableCounterpartsFields: any = [];
  
  // matching options
  nxTableTransactionsOptions: any = [];
  nxTableBankAccountsOptions: any = [];
  nxTableCounterpartsOptions: any = [];

  // matching of Ninox Field ID -> Finapi Field name e.g. { "A": "counterpartIban" }
  nxTableTransactionsMatching: any = {};
  nxTableBankAccountsMatching: any = {};
  nxTableCounterpartsMatching: any = {};

  // interval when to sync
  interval: string = "";

  // test data model
  testDataModalOpen: boolean = false;

  constructor(
    private http: HttpClient, 
    public integrationService: IntegrationService,
    private projectService: ProjectService,
    private toastController: ToastController,
    private alertController: AlertController) { }

  ngOnInit() {
  
  }

  loadConfig(){
    return new Promise(async (resolve, reject) => {
      this.loading = true;

      this.integrationService.getIntegrationSecrets(this._integration.id).then(async (secrets) => {
        this._integration.secrets = secrets;
        if(this._integration.secrets.apiKey){
          this.apiKey = this._integration.secrets.apiKey;

          if(this.apiKey != ""){
            await this.getTeams(this.apiKey).then((teams) => {
              // validate the api key by getting the teams
              teams.forEach((team) => {
                let option = {
                  title: team.name,
                  subtitle: team.id,
                  value: team.id
                } as any;
                this.nxTeamsOptions.push(option);
              });
            });
          }

          this.nxLoading = true;
          if(this.apiKey != "" && this._integration.config.team != ""){
            await this.getDatabases(this.apiKey, this.nxTeam).then((databases) => {
              databases.forEach((database) => {
                let option = {
                  title: database.name,
                  subtitle: database.id,
                  value: database.id
                } as any;
                this.nxDatabasesOptions.push(option);
              });
            });
          }

          if(this.apiKey != "" && this._integration.config.team != "" && this._integration.config.database != ""){
            this.updateTableOptions();
          }

          if(this.apiKey != "" 
          && this._integration.config.team != "" 
          && this._integration.config.database != ""
          && (this._integration.config.tableTransactions != "" || this._integration.config.tableAccounts != "" || this._integration.config.tableCounterparts != "" )){
            this.updateFieldOptions();
          }
          this.nxLoading = false;

        }
        this.loading = false;
        resolve(true);
      });  

    });
  }

  toggleVisApiKey(){
    this.showAPIKey = !this.showAPIKey;
  }

  /*
    check if the api key is valid
    load NX Teams
  */
  public validateApiKey(){
    this.apiKeyLoading = true;
    this.getTeams(this.apiKey).then((teams) => {

      // validate the api key by getting the teams
      teams.forEach((team) => {
        let option = {
          title: team.name,
          subtitle: team.id,
          value: team.id
        } as any;
        this.nxTeamsOptions.push(option);
      });
  
      // set the api key
      this._integration.secrets = {
        apiKey: this.apiKey
      };
      this._integration.setupStep = "setupWorkspace";

      this.integrationService.updateIntegration(this._integration).then((success) => {});

      this.apiKeyValid = true;
      this.apiKeyLoading = false;
      this.apiKeyDidLoad = true;
    }).catch((err) => {
      this.apiKeyValid = false;
      this.apiKeyLoading = false;
      this.apiKeyDidLoad = true;
    });
  
  }
  /*
    select a Ninox Team
  */
  onNinoxTeamChange(ev: any){
    this.nxTeam = ev.value;
    this.nxDatabasesOptions = [];
    this.nxDatabase = "";
    this.nxTablesOptions = [];
    this.nxTableTransactions = "";
    this.nxTableBankAccounts = "";
    this.nxTableCounterparts = "";
    
    this.nxLoading = true;

    this.getDatabases(this.apiKey, this.nxTeam).then((databases) => {
      this.nxDatabasesOptions = [];
      this.nxDatabase = "";
      this.nxTablesOptions = [];
      this.nxTableTransactions = "";
      this.nxTableBankAccounts = "";
      this.nxTableCounterparts = "";
      let options = [];
      databases.forEach((database) => {
        let option = {
          title: database.name,
          subtitle: database.id,
          value: database.id
        } as any;
        options.push(option);
      });
      this.nxDatabasesOptions = options;
      this.nxLoading = false;
    });

  }
  /*
    select a Ninox Database
  */
  async onNinoxDatabaseChange(ev: any){
      this.nxDatabase = ev.value;
      this.nxTablesOptions = [];
      this.nxTableTransactions = "";
      this.nxTableBankAccounts = "";
      this.nxTableCounterparts = "";
      this.nxLoading = true;
      await this.updateTableOptions();
      this.nxLoading = false;
  }
  
  async updateTableOptions(){
    this.getTables(this.apiKey, this.nxTeam, this.nxDatabase).then((tables) => {
      this.nxTablesOptions = [];
      this.nxTablesOptions.push({
        title: "---",
        subtitle: "",
        value: ""
      });


      tables.forEach((table) => {
        let option = {
          title: table.name,
          subtitle: 'TableId: '+table.id,
          value: table.id
        } as any;
        this.nxTablesOptions.push(option);
      });

    });
  }

  updateFieldOptions(table=""){

    this.mappingLoading = true;
    
    // reset
    if(!table || table=="transactions"){
      this.nxTableTransactionsFields = [];
      this.nxTableTransactionsOptions = [];
    } 
    if(!table || table=="accounts"){
      this.nxTableBankAccountsFields = [];
      this.nxTableBankAccountsOptions = [];
    } 
    if(!table || table=="counterparts"){
      this.nxTableCounterpartsFields = [];
      this.nxTableCounterpartsOptions = [];
    } 

    return new Promise(async (resolve, reject) => {

    if(!table || (table=="transactions" && this.nxTableTransactions)){
      await this.getTableFields(this.apiKey, this.nxTeam, this.nxDatabase, this.nxTableTransactions).then( (table) => {
        table.fields.forEach((field) => {

          // get the field
          this.nxTableTransactionsFields.push(field);
          
          // markup for options component
          this.nxTableTransactionsOptions.push({
            title: field.name,
            subtitle: field.id,
            value: field.id
          });

          // automatch it, if found by synonyms and not already matched
          let foreignKey = field.name.toLowerCase().trim();
          let localKey = this.integrationService.automatchFieldMapping(foreignKey, this.integrationService.getAvailableTransactionFields());

          if(localKey && !this.nxTableTransactionsMatching[field.id]){
            this.nxTableTransactionsMatching[field.id] = localKey;
          }

        });  
      });
    }

    if(!table || (table=="accounts" && this.nxTableBankAccounts)){
      console.log("here", this.nxTableBankAccounts, "accounts");

      await this.getTableFields(this.apiKey, this.nxTeam, this.nxDatabase, this.nxTableBankAccounts).then( (table) => {
        table.fields.forEach((field) => {

          // get the field
          this.nxTableBankAccountsFields.push(field);
          
          // markup for options component
          this.nxTableBankAccountsOptions.push({
            title: field.name,
            subtitle: field.id,
            value: field.id
          });

          // automatch it, if found by synonyms and not already matched
          let foreignKey = field.name.toLowerCase().trim();
          let localKey = this.integrationService.automatchFieldMapping(foreignKey, this.integrationService.getAvailableAccountFields());

          if(localKey && !this.nxTableBankAccountsMatching[field.id]){
            this.nxTableBankAccountsMatching[field.id] = localKey;
          }

        });  
      });
    }

    this.mappingLoading = false;

    return resolve(true);
    });

  }

  onNinoxTableChange(subject: string, ev: any){
    if(subject == "transactions"){
      this.nxTableTransactions = ev.value;
    } else if(subject == "accounts"){
      this.nxTableBankAccounts = ev.value;
    } else if(subject == "counterparts"){
      this.nxTableCounterparts = ev.value;
    }
  }

  onTimeIntervalChanged(value: any){
    this.interval = value;
    if(this.interval != this._integration.interval){
      this.stale = true;
    }
  }

  /*
    Changes in Konfiguration
  */
  updateIntegration(gotoNextStep: boolean = true){
    this.loading = true;
    return new Promise((resolve, reject) => {

      this._integration.config.team = this.nxTeam;
      this._integration.config.database = this.nxDatabase;
      this._integration.config.customApiUrl = this.nxCustomAPIBaseURL;

      this._integration.config.tableAccounts = this.nxTableBankAccounts;
      this._integration.config.tableTransactions = this.nxTableTransactions;
      this._integration.config.tableCounterparts = this.nxTableCounterparts;

      this._integration.config.fieldMappingAccounts = this.nxTableBankAccountsMatching;
      this._integration.config.fieldMappingTransactions = this.nxTableTransactionsMatching;
      this._integration.config.fieldMappingCounterparts = this.nxTableCounterpartsMatching;  

      if(gotoNextStep){
        if(this._integration.setupStep == "setupKey"){
          this._integration.setupStep = "setupWorkspace";
        } else if(this._integration.setupStep == "setupWorkspace"){
          this._integration.setupStep = "setupTables";
          this.updateTableOptions();
        } else if(this._integration.setupStep == "setupTables"){
          this._integration.setupStep = "setupMapping";
          this.updateFieldOptions();
        } else if(this._integration.setupStep == "setupMapping"){
          this._integration.setupStep = "completed";

          this.toastController.create({
            message: 'Integration successfully configured.',
            duration: 4000,
            position: 'top'
          }).then(toast => {
            toast.present();
          });

        } 
      }

      this.integrationService.updateIntegration(this._integration).then((success) => {
        this.loading = false;
        resolve(true);
      });
    });
  }
  /*
    Changes in Settings after configuration
  */
  saveIntegration(){
    this.loading = true;
    return new Promise((resolve, reject) => {

      if(this.interval != this._integration.interval){
        this._integration.interval = this.interval;
        delete this._integration.nextSync;
      }

      this.integrationService.updateIntegration(this._integration).then((success) => {
        this.toastController.create({
          message: 'Integration saved.',
          duration: 4000,
          position: 'top'
        }).then(toast => {
          toast.present();
        });
        this.loading = false;
        this.stale = false;
        resolve(true);
      });

    });
  }

  goStepBack(){
    if(this._integration.setupStep == "setupMapping"){
      this._integration.setupStep = "setupTables";
    } else if(this._integration.setupStep == "setupTables"){
      this._integration.setupStep = "setupWorkspace";
    } else if(this._integration.setupStep == "setupWorkspace"){
      this._integration.setupStep = "setupKey";
    } else if(this._integration.setupStep == "setupKey"){
      this.dismiss();
    }
  }

  /*
    get the teams
  */
  public getTeams(apiKey: string){
    let url = (this.nxCustomAPIBaseURL ? this.nxCustomAPIBaseURL : NINOX_API_ROOT)+'/teams';

    return this.http.get(url, {
      headers: {
        'Authorization': 'Bearer '+this.apiKey.trim(),
        'Content-Type': 'application/json; charset=utf-8'
      }
    }).toPromise()
    .then((ninoxResponse: any) => {
        return ninoxResponse;
    });
  }
  /*
    get the databases
  */
  public getDatabases(apiKey: string, teamId: string){
      let url = (this.nxCustomAPIBaseURL ? this.nxCustomAPIBaseURL : NINOX_API_ROOT)+'/teams/'+teamId+'/databases';
  
      return this.http.get(url, {
        headers: {
          'Authorization': 'Bearer '+this.apiKey.trim(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).toPromise()
      .then((ninoxResponse: any) => {
          return ninoxResponse;
      });
  }
  /*
    get the tables
  */
  public getTables(apiKey: string, teamId: string, databaseId: string){
      let url = (this.nxCustomAPIBaseURL ? this.nxCustomAPIBaseURL : NINOX_API_ROOT)+'/teams/'+teamId+'/databases/'+databaseId+'/tables';
  
      return this.http.get(url, {
        headers: {
          'Authorization': 'Bearer '+this.apiKey.trim(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).toPromise()
      .then((ninoxResponse: any) => {
          return ninoxResponse;
      });
  }

  /*
    get fields of a table
  */
    public getTableFields(apiKey: string, teamId: string, databaseId: string, tableId: string){

      console.log("table query", teamId, databaseId, tableId);

      let url = (this.nxCustomAPIBaseURL ? this.nxCustomAPIBaseURL : NINOX_API_ROOT)+'/teams/'+teamId+'/databases/'+databaseId+'/tables/'+tableId+'';
  
      return this.http.get(url, {
        headers: {
          'Authorization': 'Bearer '+this.apiKey.trim(),
          'Content-Type': 'application/json; charset=utf-8'
        }
      }).toPromise()
      .then((ninoxResponse: any) => {
          return ninoxResponse;
      });
  }

  public reconfigureIntegration(){
    this._integration.setupStep = "setupKey";
    this._integration.active = false;
    this.loadConfig().then((success) => {

    });
  }

  public openTestDataModal(){
    this.testDataModalOpen = true;
  }
  public openTestDataModalDidClose(){
    this.testDataModalOpen = false;
  }

  public dismiss(){
    this.onDismiss.emit(true);
  }

  public toggleActiveStatus(){
    return new Promise( async (resolve, reject) => {
      this.loading = true;
      this._integration.active = !this._integration.active;
      this.integrationService.updateIntegration(this._integration).then(async (success) => {
        this.loading = false;

        let toast = await this.toastController.create({
          message: 'Integration is now '+(this._integration.active ? 'active' : 'inactive')+".",
          duration: 4000,
          position: 'top'
        });
        await toast.present();


        resolve(true);
      });
    });
  }

  public deleteIntegration(){

    this.alertController.create({
      header: 'Delete Integration',
      message: 'Are you sure you want to delete this integration?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: 'Delete',
          handler: () => {
            this.integrationService.deleteIntegration(this._integration.id).then((success) => {
              if(success){
                this.onDismiss.emit(false);
              }
            });
          } 
        }
      ]
    }).then((alert) => {
      alert.present();
    });
  }

  public generateNewSyncToken(){
    this.loading = true;

    if(!this._integration.syncToken){
      this.integrationService.generateSyncToken(this._integration.id).then((response) => {
        this.loading = false;
        this._integration.syncToken = response.token;
      }).catch((err) => {
        this.loading = false;
      });
    } else {

      this.alertController.create({
        header: 'Generate new Link',
        message: 'Are you sure you want to generate a new Link? The old link will not work anymore.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
  
            }
          }, {
            text: 'Generate New',
            handler: () => {
              this.integrationService.generateSyncToken(this._integration.id).then((response) => {
                this.loading = false;
                this._integration.syncToken = response.token;
              }).catch((err) => {
                this.loading = false;
              });
            } 
          }
        ]
      }).then((alert) => {
        alert.present();
      });
    
    }

  
  }

  public deleteSyncToken(){

    this.alertController.create({
      header: 'Delete SyncToken',
      message: 'Are you sure you want to delete the SyncToken? This token will not work anymore and you will have to generate a new one.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: 'Delete',
          handler: () => {
            this.integrationService.deleteSyncToken(this._integration.id).then((success) => {
              if(success){
                this._integration.syncToken = null;
              }
            });
          } 
        }
      ]
    }).then((alert) => {
      alert.present();
    });
  }

  async test(){
    this.loading = true;
    this.integrationService.manualSyncIntegration(this._integration.id);
    this.loading = false;
  }



}
