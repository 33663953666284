import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AuthService } from '../../providers/auth-service/auth-service';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent implements OnInit {

  constructor(private navCtrl: NavController, private popoverController: PopoverController, public auth: AuthService) { }

  ngOnInit() {}

  goProfile() {
    this.navCtrl.navigateForward('/settings', {
      animated: false
    });
    this.popoverController.dismiss();
  }

  logout(){
    this.popoverController.dismiss();
    this.auth.logout();
    // @ts-ignore
    window.location.href = '/login?mode=logout';
  }

}
