import { Injectable } from "@angular/core";
import { ProjectService } from "../project-service/project-service";
import { InstitutionService } from "../institution-service/institution-service";

export interface FilterOptions {
    title: string;
    property: string;
    group: string;
    type: string;
  }

export interface Filter {
    conjunction: "AND" | "OR";
    title: string;
    property: string;
    type: string;
    operator: string;
    value: string | number | Array<string | number>;
  }

const TRANSACTION_MAPPINGS = [
    { 
      key: "id",
      title: "ID",
      description: "Internal Transaction identifier",
      example: "1234567",
      type: "string",
      synonyms: [
        "id",
        "transactionId",
        "kennung",
        "identifikation",
        "identifier",
        "internalId"
      ],
    },
    { 
      key: "amount",
      title: "Amount",
      description: "Transaction amount",
      example: "104,99",
      type: "number",
      synonyms: [
        "betrag",
        "summe",
        "amount",
        "menge",
        "saldo",
        "ausgabe",
        "einnahme",
        "buchung"
      ],
    },
    { 
      key: "currency",
      title: "Currency",
      description: "Transaction currency in ISO 4217 format.This field can be null if not explicitly provided the bank. In this case it can be assumed as accounts currency.",
      example: "EUR",
      type: "string",
      synonyms: [
        "währung",
        "currency",
        "waehrung",
        "EUR",
      ],
    },
    { 
      key: "amount+currency",
      title: "Amount combined with Currency",
      description: "Transaction amount combined with the currency of this transaction",
      example: "104,99 EUR",
      type: "string",
      synonyms: [
  
      ],
    },
    { 
      key: "purpose",
      title: "Purpose",
      description: "Transaction purpose",
      example: "Invoice 1234",
      type: "string",
      synonyms: [
        "purpose",
        "zweck",
        "verwendungszweck",
        "angabe",
        "verwendung"
      ],
    },
    {
      key: "type",
      title: "Type",
      description: 'Transaction type, according to the bank. If set, this will contain a German term that you can display to the user. Some examples of common values are: "Lastschrift", "Auslandsüberweisung", "Gebühren", "Zinsen".',
      example: "Lastschrift",
      type: "string",
      synonyms: [
        "type",
        "art",
      ],
    },
    {
      key: "valueDate",
      title: "Value Date",
      description: "Transaction value date",
      example: "2020-01-01",
      type: "date",
      synonyms: [
        "valueDate",
        "datum",
        "buchungsdatum",
        "buchung",
        "buchungszeitpunkt",
        "buchungszeit",
      ],
    },
    {
      key: "accountId",
      title: "Account ID",
      description: "Account ID of the account this transaction belongs to",
      example: "1234567",
      type: "string",
      synonyms: [
        "accountId",
        "kontoId",
        "konto",
        "account",
        "kontonummer",
        "kontoid",
      ],
    },
    {
      key: "accountName",
      title: "Account Name",
      description: "Accountholder Name of the current account",
      example: "Meine Gesellschaft mbH",
      type: "string",
      synonyms: [
        "accountName",
        "kontoinhaber",
        "konto",
        "accountOwner",
        "ownAccountName"
      ],
    },
    {
      key: "accountNumber",
      title: "Account Number",
      description: "Accountnumber of the current account",
      example: "",
      type: "string",
      synonyms: [
        "accountNumber",
      ],
    },
    {
      key: "accountIban",
      title: "Account IBAN",
      description: "IBAN of the current account",
      example: "",
      type: "string",
      synonyms: [
        "iban",
        "accountIban",
        "accountIbanNumber",
        "ownAccountIban",
      ],
    },
    {
      key: "accountBlz",
      title: "Account BLZ",
      description: "BLZ of the current account",
      example: "",
      type: "string",
      synonyms: [
        "blz",
        "accountBlz",
        "accountBlzNumber",
        "ownAccountBlz",
      ],
    },  
    {
      key: "accountBIC",
      title: "Account BIC",
      description: "BIC of the current account",
      example: "",
      type: "string",
      synonyms: [
        "bic",
        "accountBic",
        "accountBicNumber",
        "ownAccountBic",
      ],
    },  
    {
      key: "accountBankName",
      title: "Account Bank Name",
      description: "Bankname of the current account",
      example: "",
      type: "string",
      synonyms: [
        "bic",
        "accountBic",
        "accountBicNumber",
        "ownAccountBic",
      ],
    },  
    {
      key: "accountRef",
      title: "Account Table Reference",
      description: "A reference to the account table record of the current account",
      example: "",
      type: "ref",
      synonyms: [
        "bic",
        "accountBic",
        "accountBicNumber",
        "ownAccountBic",
      ],
    },  
    {
      key: "counterpartName",
      title: "Counterpart Name",
      description: "Name of the counterpart",
      example: "Max Mustermann Gmbh",
      type: "string",
      synonyms: [
        "counterpartName",
        "counterpart",
        "counterpartAccount",
        "counterpartAccountName",
        "counterpartAccountNumber",
        "counterpartAccountOwner",
        "counterpartAccountHolder",
        "counterpartAccountHolderName",
      ],
    },
    {
      key: "counterpartAccountNumber",
      title: "Counterpart Account Number",
      description: "Accountnumber of the counterpart",
      example: "",
      type: "string",
      synonyms: [
        "counterpartName",
        "counterpart",
        "counterpartAccount",
        "counterpartAccountName",
        "counterpartAccountNumber",
        "counterpartAccountOwner",
        "counterpartAccountHolder",
        "counterpartAccountHolderName",
      ],
    },
    {
      key: "counterpartIban",
      title: "Counterpart IBAN Number",
      description: "Accountnumber of the counterpart",
      example: "",
      type: "string",
      synonyms: [
        "empfänger",
        "gegenkonto",
        "gegenkontoiban",
        "gegenkontoibannummer",
        "gegenkontoiban",
        "empfängeriban",
      ],
    },
    {
      key: "counterpartBlz",
      title: "Counterpart BLZ Number",
      description: "BLZ of the counterpart",
      example: "",
      type: "string",
      synonyms: [
  
      ],
    },
    {
      key: "counterpartBic",
      title: "Counterpart BIC Number",
      description: "BIC of the counterpart",
      example: "",
      type: "string",
      synonyms: [
  
      ],
    },
    {
      key: "counterpartBankName",
      title: "Counterpart Bank Name",
      description: "BIC of the counterpart",
      example: "",
      type: "string",
      synonyms: [
  
      ],
    },
    {
      key: "importDate",
      title: "Import Date",
      description: "Date and Time when the transaction was imported",
      example: "2020-01-01T12:00:00Z",
      type: "datetime",
      synonyms: [
        "importdate",
        "importdatum",
        "import-datum",
        "import-zeitpunkt",
        "import-zeit",
      ],
    },
    {
      key: "syncDate",
      title: "Sync Date",
      description: "Date and Time when the transaction was synced with the current integration",
      example: "2020-01-01T12:00:00Z",
      type: "datetime",
      synonyms: [
        "syncdate",
        "syncronisationsdatum",
        "syncronisationdate",
        "syncronisationdatum",
      ],
    },
  ];
  
  const TIME_INTERVALS = [{
    title: "Daily (coming soon)",
    subtitle: "Transfer transactional data daily. You can choose the time of day.",
    value: "daily",
    disabled: true,
  },{
    title: "Weekly (coming soon)",
    subtitle: "Transfer transactional data weekly. You can choose the day of the week and the time of day.",
    value: "weekly",
    disabled: true,
  },{
    title: "Monthly (coming soon)",
    subtitle: "Transfer transactional data monthly. You can choose the day of the month and the time of day.",
    value: "weekly",
    disabled: true,
  }];


const FILTERABLE_FIELDS = [
    {
        title: 'Account',
        property: 'accountId',
        group: 'Account',
        type: 'account'
    },
    {
        title: 'Account Name',
        property: 'accountName',
        group: 'Account',
        type: 'string'
    },
    {
        title: 'Account Number',
        property: 'accountNumber',
        group: 'Account',
        type: 'string'
    },
    {
        title: 'Account IBAN',
        property: 'accountIban',
        group: 'Account',
        type: 'string'
    },
    {
        title: 'Account BLZ',
        property: 'accountBlz',
        group: 'Account',
        type: 'string'
    },
    {
        title: 'Account BIC',
        property: 'accountBic',
        group: 'Account',
        type: 'string'
    },
    {
        title: 'Account Bank Name',
        property: 'accountBankName',
        group: 'Account',
        type: 'string'
    },
    {
        title: 'Counterpart Name',
        property: 'counterpartName',
        group: 'Counterpart',
        type: 'string'
    },
    {
        title: 'Counterpart Account Number',
        property: 'counterpartAccountNumber',
        group: 'Counterpart',
        type: 'string'
    },
    {
        title: 'Counterpart IBAN',
        property: 'counterpartIban',
        group: 'Counterpart',
        type: 'string'
    },
    {
        title: 'Counterpart BLZ',
        property: 'counterpartBlz',
        group: 'Counterpart',
        type: 'string'
    },
    {
        title: 'Counterpart BIC',
        property: 'counterpartBic',
        group: 'Counterpart',
        type: 'string'
    },
    {
        title: 'Counterpart Bank Name',
        property: 'counterpartBankName',
        group: 'Counterpart',
        type: 'string'
    },
    {
        title: 'Amount',
        property: 'amount',
        group: 'Amount',
        type: 'currency'
    }
];

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    constructor(private projectService: ProjectService, private institutionService: InstitutionService) { }

    /*
        Get all filterable fields
    */
    getFilterableFields(dataset: string = "transactions") {
        return FILTERABLE_FIELDS as FilterOptions[];
    }

    /*
        Turn Array of FilterOptions into a filter string
    */
    buildFilterString(filter: Filter[]) {

        let filterString = "";

        filter.forEach((option, index) => {
            if (index > 0) {
              filterString += " "+option.conjunction+" ";
            } else {
              filterString += "WHERE ";
            }

            let operatorParsed = option.operator;
           
            let valueParsed = option.value;
            if(option.type == "string") {
              valueParsed = "'" + valueParsed + "'";
            } else if(option.type == "number") {
              valueParsed = valueParsed;
            } else if(option.type == "currency") {
              valueParsed = valueParsed;
            } else if(option.type == "account") { // account is a special case, its an array
              valueParsed = JSON.stringify(valueParsed);
            }

            filterString += option.property + " " + operatorParsed + " '" + option.value + "'";
        });
        return filterString;
    }

    /*
        Turn a filter string into an Array of FilterOptions
    */
    parseFilterString(filterString: string) {
        let filterOptions: Filter[] = [];

        // split string by AND and OR
        let filterArray = filterString.split(/(AND|OR)/);

        filterArray.forEach((filter, index) => {
            let filterOption: Filter = {
                conjunction: "AND",
                title: '',
                property: '',
                type: '',
                operator: '',
                value: ''
            };

            let filterSplit = filter.split(" ");

            filterOption.value = filterSplit[0];
            filterOption.property = filterSplit[1];
            filterOption.value = filterSplit[2];

            filterOptions.push(filterOption);
        });

        return filterOptions;
    }

    /*
        Get Conditions for a given type
    */
    getTypeOperators(type = "string"): any[] {
        if(type == "string") {
            return [{
                title: 'is',
                value: '=='
            },{
                title: 'is not',
                value: '!='
            },{
                title: 'is empty',
                value: '== $empty'
            },{
                title: 'is not empty',
                value: '!= $empty'
            }];
        } else if(type == "number" || type == "currency"){
            return [{
                title: 'is',
                value: '=='
            },{
                title: 'is not',
                value: '!='
            },{
                title: 'is greater than',
                value: '>'
            },{
                title: 'is greater than or equal to',
                value: '>='
            },{
                title: 'is less than',
                value: '<'
            },{
                title: 'is less than or equal to',
                value: '<='
            }];
        } else if(type == "account"){
          return [{
              title: 'is',
              value: '=='
          },{
              title: 'is not',
              value: '!='
          }];
        }
    }

    /*
      Get specific values if type is not ambiguous
    */
    getValuesForType(type: string) {

      if(type == "account") {

        let accounts = this.projectService.getCachedAccounts();
        let options = [];
        accounts.forEach((account) => {
          options.push({
            title: account.accountName,
            subtitle: this.institutionService.formatIban(account.iban),
            value: account.id
          });
        });        
        return options;
        
      } else {
        return [];
      }
    }

}
