import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';

import { AnySwitchPopoverComponent } from '../any-switch-popover/any-switch-popover.component';
import { CreateProjectPage } from '../../pages/create-project/create-project.page';
import { BehaviorSubject, Observable, Subscribable } from 'rxjs';

export interface Option {
  title: string;
  subtitle: string;
  class: string;
  classTitle?: string;
  classSubtitle?: string;
  value: string;
  group: string;
  icon: string;
  image: string;
  disabled: boolean;
  clearOthers?: boolean;
}

@Component({
  selector: 'any-switch',
  templateUrl: './any-switch.component.html',
  styleUrls: ['./any-switch.component.scss'],
})
export class AnySwitchComponent implements OnInit {

  @ViewChild('inner',{static: false}) innerEle;
  @HostBinding('class') class = 'default-switch';
  @HostBinding('class') get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  @Input() search: boolean = false;
  @Input() searchTreshold: number = 999;
  
  @Input() selectDefault: boolean = true;

  @Input() multiple: boolean = false;

  _selectedOption: Option;
  _selectedOptions: Array<Option> = [];
  _rawSelected: any;

  @Input()
  set selected(val: any) {
    let opt = null;
    this._rawSelected = val;
    if (!val || val == null) {
      this._selectedOption = this._options[0];
      this.class = 'default-switch';
    } else {
      if(!this.multiple){
        this._options.forEach(option => {
          if (option.value == val) opt = option;
        });
        this._selectedOption = opt;
        if (this._selectedOption && this._selectedOption.class) {
          this.class = this._selectedOption.class;
        } else {
          this.class = 'default-switch';
        }
      } else {
        let vals = [];
        if(val instanceof Array){
           vals = val;
        } else {
          vals = String(val).split(',');
          vals = vals.map((v)=>{return v.trim()});
        }
      
        this._selectedOptions = [];
        this._options.forEach(option => {
          if (vals.indexOf(option.value) > -1) this._selectedOptions.push(option);
        });
      }
    }
  }
  get selected(): any {
    if(!this.multiple){
      return this._selectedOption;
    } else {
      return this._selectedOptions;
    }
   }

  _options: Array<Option>;
  @Input()
  set options(options: Array<Option>) {
    this._options = options;
    if(this._options && !this._selectedOption && this.selectDefault){
      if(!this.multiple){
        this._selectedOption = this._options[0];
        this.class = 'default-switch';
      } else {
        this._selectedOptions = [this._options[0]];
      }
    } 

    // enable search if big enough
    if(this._options && this._options.length > this.searchTreshold) this.search = true;

    // re-trigger selected
    if(this._rawSelected) this.selected = this._rawSelected;

  }
  get options(): Array<Option> { return this._options; }

  @Input() groupedBy: string = "";

  @Input() emptyLabel: string;

  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;

  @Output() change = new EventEmitter<any>();

  _popover: any;

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController) {}

  ngOnInit() {
  }

  async presentPopover(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();

    if(this.disabled) return false;

    let fakeEv = JSON.parse(JSON.stringify(ev));
    fakeEv.target = this.innerEle.nativeElement;

    this._popover = await this.popoverController.create({
      component: AnySwitchPopoverComponent,
      event: fakeEv,
      translucent: true,
      showBackdrop: false,
      leaveAnimation: null,
      cssClass: this.class,
      componentProps: {
        groupedBy: this.groupedBy,
        options: this._options,
        multiple: this.multiple,
        selected: (!this.multiple ? this._selectedOption : this._selectedOptions),
        loading: this.loading,
        disabled: this.disabled,
        search: this.search,
        parent: this
      }
    });
    await this._popover.present();
    this._popover.onWillDismiss().then((ev)=>{
      if(ev && ev.data && ev.data.selected){
        this._selectedOption = ev.data.selected;
        this.change.emit(this._selectedOption);
      }
    });
    return false;
  }

  public setSelected(option: Option) {
    this._selectedOption = option;
    this.change.emit(this._selectedOption);
  }

  public setSelectedOptions(options: Array<Option>) {
    this._selectedOptions = options;

    if(!options || options.length == 0){
      this._options.forEach(option => {
        if (option.value == "") this._selectedOptions.push(option);
      });
    }

    this.change.emit(this._selectedOptions);
  }

}
