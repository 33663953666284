import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ItemReorderEventDetail, ToastController } from '@ionic/angular';

import { Filter, FilterOptions, FilterService } from 'src/app/providers/filter-service/filter.service';
import { ProjectService } from 'src/app/providers/project-service/project-service';

@Component({
  selector: 'filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrls: ['./filter-builder.component.scss'],
})
export class FilterBuilderComponent implements OnInit {

  @Input() options: FilterOptions[];
  @Input() intro: string = "";

  _conditions: Filter[] = [];

  private _filter: string;
  @Input() set filter(value: string) {
    this._filter = value;
    this._conditions = this.filterService.parseFilterString(value);
  }
  get filter(): any {
    return this.filterService.buildFilterString(this._conditions);
  }
  @Output() change: EventEmitter<string> = new EventEmitter();

  _availableConditions: any[] = [];

  _loadingValues: boolean = false;
  _valueType: string = "text";
  _availableValues: any[] = [];

  constructor(private filterService: FilterService, 
        private projectService: ProjectService,
        private toastController: ToastController) { 

  }

  ngOnInit() {
    this.options = this.filterService.getFilterableFields("transactions");
  }

  /*
    1. Select a Property
  */
  async selectProperty(index: number, option: FilterOptions) {
 
    let selectedOption = option;

    // get the condition by index
    let condition = this._conditions[index];
    condition.type = selectedOption.type;
    condition.property = selectedOption.property;

    // emit change event
    this.change.emit(this.filter);

  }
  /*
    2. Select an Operator
  */
  async selectOperator(index: number, operator: any) {
    // get the condition by index
    let condition = this._conditions[index];
    condition.operator = operator.value;

    // emit change event
    this.change.emit(this.filter);

  }
  /*
    3. Select a Value
    ev could be html event, custom event or any-switch event
  */
  async setValue(index: number, ev: any) {


    let value = ev.target?.value;
    if(value == undefined) value = ev.detail?.value;
    if(value == undefined) value = ev.value;

    // might be an array of options from any-switch
    if(value == undefined) {
      value = "";
      for(let i = 0; i < ev.length; i++){
        if(i > 0) value += ",";
        value += ev[i].value;
      }
    }

    // get the condition by index
    let condition = this._conditions[index];
    if(condition.type == "string"){
      value = String(value);
    } else if(condition.type == "number"){
      value = Number(value);
    } else if(condition.type == "account"){
      value = value;
    }
    condition.value = value;

    // emit change event
    this.change.emit(this.filter);

  }

  /*
    get Available stuff from the FilterService
  */
  getOperators(type = "string") {
    return this.filterService.getTypeOperators(type);
  }

  getValueCollection(type: string) {
    return this.filterService.getValuesForType(type);
  }

  /*
    Add a new Condition
  */
  addCondition() {
    let option = this.options[0];
    let operator = this.getOperators(option.type)[0];
    this._conditions.push({
      conjunction: "AND",
      title: option.title,
      property: option.property,
      type: option.type,
      operator: operator.value,
      value: ""
   });
  }
  /*
    Remove a Condition by Index
  */
  removeCondition(index: number){
    this._conditions.splice(index, 1);
  }
  /*
    Update reorder the conditions
  */
  reorderConditions(ev: CustomEvent<ItemReorderEventDetail>) {
 
      this._conditions = ev.detail.complete(this._conditions);
    }

  copyFilterString(ev: any) {

    ev.preventDefault();
    ev.stopPropagation();

    let filterString = this.filterService.buildFilterString(this._conditions);

    navigator.clipboard.writeText(filterString).then(() => {
      this.toastController.create({
        message: 'Filter copied to clipboard!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t copy filter to clipboard.',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
      console.log('Could not copy text: ', err);
    });

  }

  pasteFilterString(ev: any) {

    ev.preventDefault();
    ev.stopPropagation();

    navigator.clipboard.readText().then((clipText) => {
      try {
        let filterString = clipText;
        this._conditions = this.filterService.parseFilterString(filterString);
      } catch (err) {
        this.toastController.create({
          message: 'Filter not valid.',
          duration: 2000,
          position: 'top',
          cssClass: 'toast-error'
        }).then((toast) => {
          toast.present();
        });
      }
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t paste filter from clipboard.',
        duration: 2000,
        position: 'top',
        cssClass: 'toast-error'
      }).then((toast) => {
        toast.present();
      });
      console.log('Could not paste text: ', err);
    });


  }

}
