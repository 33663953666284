import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { APIKey } from 'src/app/models/APIKey';
import { APIService } from 'src/app/providers/api-service/api-service';
import { InstitutionService } from 'src/app/providers/institution-service/institution-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';


@Component({
  selector: 'api-key-list',
  templateUrl: './api-key-list.component.html',
  styleUrls: ['./api-key-list.component.scss'],
})
export class ApiKeyListComponent implements OnInit {

  _apiKeys: APIKey[] = [];
  @Input()
  set apiKeys(apiKeys: any) {
    
    // standardize the apiKeys
    apiKeys.forEach((key) => {
      if(!key.scopes) key.scopes = ['ALL'];
      if(!key.accounts) key.accounts = [''];
      if(!key.allowedIPs) key.allowedIPs = [];
      if(!key.active) key.active = false;
      if(!key.createdAt) key.createdAt = new Date();
    });

    this._apiKeys = apiKeys;
  }
  get apiKeys(): any {
    return this._apiKeys;
  }

  @Output() itemChanged = new EventEmitter<any>();

  editName: boolean = false;
  newName: string = '';
  
  keysLoading: any[] = [];
  isEdited: boolean = false;

  accountOptions: any = [];

  constructor(public projectService: ProjectService, 
    public apiService: APIService, 
    public institutionService: InstitutionService,
    private toastController: ToastController,
    private translate: TranslationBridge) { }

  ngOnInit() {
    
    /*
    this._apiKeys = [
      {
        "name": "Meine n8n Test Integration Marcel",
        "active": true,
        "key": "testkey1testkey1testkey1testkey1testkey1",
        "createdAt": new Date(),
        "expiresAt": new Date(),
        "lastUsedAt": new Date(),
        "scopes": ['ALL', 'ACCOUNTS', 'TRANSACTIONS'],
        "accounts": [],
        "allowedIPs": [],
      },
    ];*/

    
    this.institutionService.getAccountsAsOptions(this.projectService.currentProject.id).then((options) => {
      let emptyOption = {
          value: '',
          title: this.translate.get('ACCOUNT_SELECT_ALL'),
          subtitle: this.translate.get('ACCOUNT_SELECT_ALL_INFO'),
          clearOthers: true
        };
      this.accountOptions = [emptyOption, ...options];
    });

  }

  /*
    Form functions
  */

  public enterEditName(ev: any, index: number){
    ev.stopPropagation();
    ev.preventDefault();
    this.newName = this._apiKeys[index].name;
    this.editName = true;
  }
  public acceptEditName(ev: any, index: number){
    ev.stopPropagation();
    ev.preventDefault();
    this._apiKeys[index].name = this.newName;
    this.editName = false;
    this.isEdited = true;
  }
  public cancelEditName(ev: any, index: number){
    ev.stopPropagation();
    ev.preventDefault();
    this.editName = false;
  }

  public async copyKey(index: number, ev: any){
    ev.stopPropagation();
    ev.preventDefault();

    const keyObj = this._apiKeys[index];

    if(this.keysLoading.indexOf(keyObj.id) > -1) return;

    let key = keyObj.key;
    if(key.indexOf('...') > -1){
      this.keysLoading.push(keyObj.id);
      key = await this.getFullApiKey(index);
      this.keysLoading = this.keysLoading.filter((k) => k != keyObj.id);
    }
    this._apiKeys[index].key = key;

    // copy to clipboard via navigator
    navigator.clipboard.writeText(key).then(() => {
      this.toastController.create({
        message: 'Key copied to clipboard!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t copy key to clipboard.',
        duration: 2000,
        position: 'top'
        }).then((toast) => {
          toast.present();
        });
      });
  }

  public async toggleActiveStatus(index: number, ev: any){

    this._apiKeys[index].active = !this._apiKeys[index].active;
    this.isEdited = true;

    if(this._apiKeys[index].active){
      await this.apiService.activateKey(this._apiKeys[index].id);
    } else {
      await this.apiService.deactivateKey(this._apiKeys[index].id);
    }
    this.toastController.create({
      message: (this._apiKeys[index].active ? 'Key activated.' : 'Key deactivated.'),
      duration: 2000,
      position: 'top'
    }).then((toast) => {
      toast.present();
    });
  }

  public setScope(index: number, ev: Array<any>){
    const selected = ev.map(e => e.value);
    this._apiKeys[index].scopes = selected;
    if(this._apiKeys[index].scopes.length == 0){
      this._apiKeys[index].scopes = ['ALL'];
    }
    this.isEdited = true;
  }
  public setAccounts(index: number, ev: any){
    const selected = ev.map(e => e.value);
    this._apiKeys[index].accounts = selected;
    this.isEdited = true;
  }

  public saveKey(index: number, ev: any){

    if(ev){
      ev.stopPropagation();
      ev.preventDefault();
    }
    const keyObj = this._apiKeys[index];

    if(!keyObj.id){
      this.apiService.addKey(keyObj).then((res: APIKey) => {
        this.apiService.generateAPISecret(res.id).then((secret) => {
          this.isEdited = false;

          this.toastController.create({
            message: 'API Key created!',
            duration: 2000,
            position: 'top'
          }).then((toast) => {
            toast.present();
          });
        });
      }).catch((err) => {
        this.toastController.create({
          message: 'Couldn\'t create key. Please try again.',
          duration: 2000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
      });
    } else {
      this.apiService.updateKey(keyObj).then((res: APIKey) => {
        this.isEdited = false;

        this.toastController.create({
          message: 'API Key updated!',
          duration: 2000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
      }).catch((err) => {
        this.toastController.create({
          message: 'Couldn\'t update key. Please try again.',
          duration: 2000,
          position: 'top'
        }).then((toast) => {
          toast.present();
        });
      });
    }


  }

  public regenerateKey(index: number, ev: any){
    ev.stopPropagation();
    ev.preventDefault();
    const keyObj = this._apiKeys[index];
    this.keysLoading.push(keyObj.id);
    this.apiService.generateAPISecret(keyObj.id).then((secret) => {
      this.isEdited = false;

      this._apiKeys[index].key = secret;

      this.apiService.updateKey(this._apiKeys[index]);

      this.keysLoading = this.keysLoading.filter((k) => k != keyObj.id);

      this.toastController.create({
        message: 'API Key regenerated!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t regenerate key. Please try again.',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    });
    
  }

  public deleteKey(index: number, ev: any){
    ev.stopPropagation();
    ev.preventDefault();
    const keyObj = this._apiKeys[index];
    this.apiService.deleteKey(keyObj.id).then((res) => {
      this.isEdited = false;
      setTimeout(() => {
        this.itemChanged.emit();
      },300);
      this.toastController.create({
        message: 'API Key deleted!',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    }).catch((err) => {
      this.toastController.create({
        message: 'Couldn\'t delete key. Please try again.',
        duration: 2000,
        position: 'top'
      }).then((toast) => {
        toast.present();
      });
    });
  }

  apiKeyMessages(index: number){
    var messages = [];
    var key = this._apiKeys[index];
    if(!key.active){
      messages.push({
        type: "warning",
        icon: "power",
        title: "API Key is inactive",
        text: this.translate.get('API_KEY_OFFLINE_INFO'),
        actionTitle: "",
        action: null
      });
    }
    return messages;
  }

  async getFullApiKey(index: number): Promise<string> {
    const key = await this.apiService.getAPISecret(this._apiKeys[index].id);
    return key || "";
  }

  public getAccountName(id: number){
    let account = this.accountOptions.find((a) => a.value == id);
    if(account) return account.title;
    return id.toString();
  }
  public getAccountIban(id: number){
    let account = this.accountOptions.find((a) => a.value == id);
    if(account) return account.subtitle;
    return id.toString();
  }

  public keyIsLoading(keyId: any){
    return this.keysLoading.indexOf(keyId) > -1;
  }

}
