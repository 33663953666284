// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// Import the functions you need from the SDKs you need

// https://firebase.google.com/docs/web/setup#available-libraries
 // http://localhost:8200
 // http://api.wrapi.io

 export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAc0_JDvyHkjZmI9PoKptnjHq-r73kk0g0",
    authDomain: "kontoflux-io.firebaseapp.com",
    projectId: "kontoflux-io",
    storageBucket: "kontoflux-io.appspot.com",
    messagingSenderId: "425543386978",
    appId: "1:425543386978:web:2872933ab2038a84b54e3b"
  },
  systemAPI: 'https://sys-api.kontoflux.io/v1',
  productDomain: 'kontoflux.io',
  productBackend: 'app.kontoflux.io',
  finapiBase: 'https://live.finapi.io',
  finapiWebformBase: 'https://webform-live.finapi.io',
  finAPIEmbedding: true,
  stripeKey: "pk_live_51NJtNnJ5NyVE9mNvKpdrmtpz81eTdoSI7XS06OnD5Uqtnz7gaRvkekIWBW1kVzIUTpacG3dDfw9i7ZHnVNGiz39S00ztjlK1Xz",
  useHttps: false,
  version: '0.7.1',
};

/*
  finapiBase: 'https://live.finapi.io',
  finapiWebformBase: 'https://webform-live.finapi.io',
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
