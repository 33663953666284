import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './../auth-service/auth-service';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

  notifications: any = [];

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ){
    setTimeout(() => {
      if(this.auth.currentUser && this.auth.currentUser.email && !this.auth.currentUser.emailVerified){
       // this.addNotification("Please verify your email address 📫","email-verification");
      }
    }, 8000);
  }

  addNotification(message="",type="default"){
    if(type != "default"){
      if(this.notifications.find( notif => notif.type == type)) return false;
    }
    if(type == "system-error"){
      this.notifications.unshift({
        message: message,
        type: type
      });
    } else {
      this.notifications.push({
        message: message,
        type: type
      });
    }
  }

  async getTaskFeed(projectId: string){
    const token = await this.auth.getAuthToken();
    const headers = { 'Authorization': token };
    const result = await this.http.get(environment.systemAPI + '/' + projectId + '/institutions/tasks', { headers: headers }).toPromise();
    return result;
  }

  dismiss(){
    this.notifications.shift();
  }

  hasNotifications(){
    return (this.notifications.length > 0);
  }

}
