import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'silent-footer',
  templateUrl: './silent-footer.component.html',
  styleUrls: ['./silent-footer.component.scss'],
})
export class SilentFooterComponent  implements OnInit {

  currentYear = "";

  constructor() { 
    this.currentYear = new Date().getFullYear().toString();
  }

  ngOnInit() {}

}
