import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';

import { SplitPageComponent } from './split-page/split-page.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { ProjectSwitchComponent } from './project-switch/project-switch.component';
import { ProjectSwitchPopoverComponent } from './project-switch-popover/project-switch-popover.component';

import { AnySwitchComponent } from './any-switch/any-switch.component';
import { AnySwitchPopoverComponent } from './any-switch-popover/any-switch-popover.component';
import { LinkCopyBoxComponent } from './link-copy-box/link-copy-box.component';

import { ProfilePopoverComponent } from './profile-popover/profile-popover.component';

import { IconToggleComponent } from './icon-toggle/icon-toggle.component';
import { MemberBubbleComponent } from './member-bubble/member-bubble.component';
import { MemberItemComponent } from './member-item/member-item.component';

import { InstitutionAccountListComponent } from './institution-account-list/institution-account-list.component';
import { BankListComponent } from './bank-list/bank-list.component';
import { BankListFullComponent } from './bank-list-full/bank-list-full.component';

import { IntegrationListComponent } from './integration-list/integration-list.component';

import { NinoxIntegrationFormComponent } from './integration-forms/ninox/ninox.component';
import { IntegrationFieldMapperComponent } from './integration-field-mapper/integration-field-mapper.component';

import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';

import { LoaderComponent } from './loader/loader.component';
import { SilentFooterComponent } from './silent-footer/silent-footer.component';

import { IntegrationTestDataModalComponent } from './integration-test-data-modal/integration-test-data-modal.component';

import { FilterBuilderComponent } from './filter-builder/filter-builder.component';
import { IntervalBuilderComponent } from './interval-builder/interval-builder.component';

import { toLowerCasePipe } from './../pipes/toLowerCase';
import { shortNumberPipe } from './../pipes/shortNumber';
import { shortUrlPipe } from './../pipes/shortUrl';
import { initialsPipe } from './../pipes/initials';
import { ibanPipe } from '../pipes/iban';
import { intervalPipe } from '../pipes/interval';
import { truncateMiddlePipe } from '../pipes/truncateMiddle';

import { SubscriptionLabelComponent } from './subscription-label/subscription-label.component';
import { SubscriptionClockComponent } from './subscription-clock/subscription-clock.component';

import { FaqListComponent } from './faq-list/faq-list.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { ApiKeyListComponent } from './api-key-list/api-key-list.component';
import { WebhookListComponent } from './webhook-list/webhook-list.component'; 
import { EmojiButtonComponent } from './emoji-button/emoji-button.component';


export const components = [
  AppHeaderComponent,
  ProjectSwitchComponent,
  ProjectSwitchPopoverComponent,
  AnySwitchComponent,
  AnySwitchPopoverComponent,
  FilterBuilderComponent,
  IntervalBuilderComponent,
  LinkCopyBoxComponent,
  ProfilePopoverComponent,
  SplitPageComponent,
  IconToggleComponent,
  MemberBubbleComponent,
  NotificationBannerComponent,
  NotificationMessageComponent,
  FaqListComponent,
  LoaderComponent,
  InstitutionAccountListComponent,
  BankListComponent,
  BankListFullComponent,
  TransactionListComponent,
  IntegrationListComponent,
  IntegrationFieldMapperComponent,
  NinoxIntegrationFormComponent,
  IntegrationTestDataModalComponent,
  ApiKeyListComponent,
  WebhookListComponent,
  MemberItemComponent,
  SubscriptionLabelComponent,
  SubscriptionClockComponent,
  SilentFooterComponent,
  EmojiButtonComponent
];

export const pipes = [
  toLowerCasePipe,
  shortNumberPipe,
  shortUrlPipe,
  initialsPipe,
  ibanPipe,
  intervalPipe,
  truncateMiddlePipe
];


@NgModule({
  declarations: [components, pipes],
  imports: [CommonModule,FormsModule,IonicModule, MomentModule, TranslateModule,RouterModule],
  exports: [components, pipes, MomentModule]
})
export class ComponentsModule {}
