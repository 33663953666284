import { Component, OnInit, Input } from '@angular/core';
import { FAQ } from 'src/app/models/FAQ';
import { FAQService } from 'src/app/providers/faq-service/faq-service';

@Component({
  selector: 'faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss'],
})
export class FaqListComponent implements OnInit {

  faqs: FAQ[] = [];

  _category: string = "";
  @Input()
  set category(category: string) {
    this._category = category;
    this.faqs = this.faqService.getFAQS(this.category);
  }
  get category(): string {
    return this._category;
  }

  @Input() search: string = "";
  @Input() showSearch: boolean = true;
  @Input() maxDisplay: number = 15;

  constructor(public faqService: FAQService) { }

  ngOnInit() {
  
  }

}
