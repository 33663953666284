import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { PopoverController, ModalController } from '@ionic/angular';
import { AnySwitchComponent } from '../any-switch/any-switch.component';

import FuzzySearch from 'fuzzy-search'; // Or: var FuzzySearch = require('fuzzy-search');
import { BehaviorSubject, Subscription } from 'rxjs';


interface Option {
  title: string;
  subtitle: string;
  class: string;
  value: string;
  group: string;
  icon: string;
  image: string;
  disabled: boolean;
  clearOthers?: boolean;
}

interface GroupedOption {
  title: string;
  icon: string;
  options: Array<Option>;
}

@Component({
  selector: 'any-switch-popover',
  templateUrl: './any-switch-popover.component.html',
  styleUrls: ['./any-switch-popover.component.scss'],
})
export class AnySwitchPopoverComponent implements OnInit {

  @Input() parent: AnySwitchComponent;

  @Input() multiple: boolean = false;
  @Input() search: boolean = false;

  _searchTerm: string = "";

  _selectedOption: Option;
  _selectedOptions: Array<Option> = [];

  @Input()
  set selected(option: Option | Array<Option>) {
    if(!option) return ;
    if(!this.multiple){
      this._selectedOption = option as Option;
    } else {
      console.log(option,'SET IT <--------');
      if(option instanceof Array){ 
       this._selectedOptions = option as Array<Option>;
      } else {
        this._selectedOptions = [option as Option];
      }
    }
  }
  get selected(): Option | Array<Option> {
    if(!this.multiple){
      return this._selectedOption;
    } else {
      return this._selectedOptions;
    }
   }

  _options: Array<Option>;
  _optionsGrouped: Array<GroupedOption>;
  @Input()
  set options(options: Array<Option>) {
    this._options = options;
    if(this._options && !this._selectedOption) this.selected = this._options[0];

    if(this.groupedBy){
      this._optionsGrouped = [];
      let groups = [];
      this._options.forEach(option => {
        if(!groups.includes(option[this.groupedBy])) groups.push(option[this.groupedBy]);
      });
      groups.forEach(group => {
        let groupOptions = [];
        this._options.forEach(option => {
          if(option[this.groupedBy] == group) groupOptions.push(option);
        });
        this._optionsGrouped.push({
          title: group,
          icon: "",
          options: groupOptions
        });
      });
    }

  }
  get options(): Array<Option> { return this._options; }

  @Input() groupedBy: string = "";

  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController,
    public navCtrl: NavController,) {}

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  onSelection(option: Option, ev?: any){
    if(option.disabled) return ;

    if(!this.multiple){
      this.selected = option;
      this.popoverController.dismiss({
        selected: option
      });
    } else {
      
      if(option.clearOthers){
        this.parent._selectedOptions = [];
      } else {
        this.parent._selectedOptions = this.parent._selectedOptions.filter(o => !o.clearOthers);
      }
      let mapped = this.parent._selectedOptions.map(o => o.value);
      if(mapped.indexOf(option.value) > -1){
        this.parent._selectedOptions = this.parent._selectedOptions.filter(o => o.value != option.value);
      } else {
        this.parent._selectedOptions.push(option);
      }
      this._selectedOptions = this.parent._selectedOptions;
      this.parent.setSelectedOptions(this._selectedOptions);
    }
  }

  isSelected(option: Option){
    if(!this.multiple){
      return this.parent.selected.value == option.value;
    } else {
      for(let i = 0; i < this._selectedOptions.length; i++){
        if(this._selectedOptions[i].value == option.value) return true;
      }
    }
  }

  onSearch(ev: any){
    // filter options
    
  }

  applySearchFilter(options: Option[]){
    if(!this._searchTerm) return options;

    const searcher = new FuzzySearch(options, ['title', 'subtitle', 'value'], {
      caseSensitive: false,
    });
    const result = searcher.search(this._searchTerm);

    return result;
  }


}
