import { Input, Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { ProjectMember } from 'src/app/models/ProjectMember';
import { AuthService } from 'src/app/providers/auth-service/auth-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';

@Component({
  selector: 'member-bubble',
  templateUrl: './member-bubble.component.html',
  styleUrls: ['./member-bubble.component.scss'],
})
export class MemberBubbleComponent implements OnInit {

  @Input() user: ProjectMember;
  @ViewChild('popover') popover;

  @Output() reload = new EventEmitter<boolean>();

  popoverOpen: boolean = false;

  constructor(private element: ElementRef, 
        private projectService: ProjectService, 
        private auth: AuthService, 
        private toastController: ToastController,
        private alertController: AlertController) { }

  ngOnInit() {}

  presentPopover(e: Event) {
    this.popover.event = {
      target: this.element.nativeElement.querySelector('.popover-anchor'),
    };
    this.popoverOpen = true;
  }

  currentUserIsAdmin(){
    let currentUser = this.auth.currentUser.uid;
    let project = this.projectService.getCurrentProject();
    if(project && currentUser){
      return project.owner.includes(currentUser);
    }
  }

  async openEditModal(){
  
  }

  async removeUser(){

    let confirm = await this.alertController.create({
      header: 'Remove Member',
      message: 'Are you sure you want to remove this member from the workspace?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.popoverOpen = false;
          }
        },
        {
          text: 'Remove',
          handler: async () => {


            this.popoverOpen = false;

            let currentUser = this.auth.currentUser.uid;
            let project = this.projectService.getCurrentProject();     
            if(project && currentUser){
              if(this.user.uid == currentUser){
                this.toastController.create({
                  message: 'You cannot remove yourself from the workspace.',
                  duration: 5000,
                  cssClass: 'toast-error',
                  position: 'top',
                  buttons: [
                    {
                      text: 'OK',
                      role: 'cancel',
                      handler: () => {
                      }
                    }
                  ]
                }).then(toast => {
                  toast.present();
                });
                confirm.dismiss();
              }
        
              // @ts-ignore
              if(this.user.code){
                // @ts-ignore
                await this.projectService.deleteInvite(project,this.user.code);
              } else {
                await this.projectService.removeMember(project, this.user.uid);
              }
        
              this.toastController.create({
                message: 'Successfully removed member.',
                duration: 5000,
                cssClass: 'toast-success',
                position: 'top',
                buttons: [
                  {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => {
                    }
                  }
                ]
              }).then(toast => {
                toast.present();
              });      
        
              this.reload.emit(true);
              confirm.dismiss();

            }

          }
        }
      ]
    }).then((alert) => {
      alert.present();
      return alert;
    });
  }
}
