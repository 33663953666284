import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'icon-toggle',
  templateUrl: './icon-toggle.component.html',
  styleUrls: ['./icon-toggle.component.scss'],
})
export class IconToggleComponent implements OnInit {

  @Input() icon: string;
  @Input() iconActive: string;

  _active: boolean;
  @Input()
  set active(val: boolean) {
    this._active = val;
  }
  get active(): boolean { return this._active; }
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit() {}

  toggle(){
    if(this.disabled){
      this.change.emit(this.active);
      return false;
    }
    if(this.active) this.active = false;
      else this.active = true;

    this.change.emit(this.active);

    return true;

  }


}
