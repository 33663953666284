import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';

import { ConnectBankAccountPage } from './connect-bank-account.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ComponentsModule
  ],
  declarations: [ConnectBankAccountPage],
  entryComponents: [ConnectBankAccountPage]
})
export class ConnectBankAccountPageModule {}
