import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'emoji-button',
  templateUrl: './emoji-button.component.html',
  styleUrls: ['./emoji-button.component.scss'],
})
export class EmojiButtonComponent  implements OnInit {

  @ViewChild('popover') popover: any;
  @ViewChild('emojipicker') picker: ElementRef;

  _icon: string = '📎';
  @Input() set icon(icon: string) {
    if(icon === undefined || icon === null || icon === '') {
      icon = '📎';
    }
    this._icon = icon;
  }
  get icon() {
    return this._icon;
  }

  isOpen: boolean = false;

  @Output() selected = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {}

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  afterPopoverRendered() {
    this.picker.nativeElement.addEventListener('emoji-click', (e: any) => {
      this._icon = e.detail.unicode;
      this.selected.emit(this.icon);
      this.isOpen = false;
    });
  }

  closePopover() {
    this.isOpen = false;
  }

}
