import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationBridge {

  translations = {
  };
  loadedTranslations = [];

  constructor(public translate: TranslateService) {
    this.loadTranslations();
  }
  /*
    (re)-load translations
  */
  loadTranslations(opts?){

    // reset loaded translation, example: change the language during runtine
    if(opts && opts.reset){
      this.loadedTranslations = [];
    }
    /* -- load translations -- */
    let translationIndex = Object.keys(this.translations);
    for(let i = 0; i < translationIndex.length; i++){
      if(this.loadedTranslations.indexOf(translationIndex[i]) != -1) continue;
      this.translate.get(translationIndex[i]).subscribe(
        value => {
          this.translations[translationIndex[i]] = value;
          this.loadedTranslations.push(translationIndex[i]);
        }
      );
    }
  }
  /*
    get a translation
  */
  public get(translation = ""){
    if(this.loadedTranslations.indexOf(translation) == -1){
      this.translations[translation] = "";
      this.loadTranslations();
    }
    return this.translations[translation];
  }

}
