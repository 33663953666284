import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth-service/auth-service';
import { Institution } from 'src/app/models/Institution';
import { ConnectedInstitution } from 'src/app/models/ConnectedInstitution';
import { Transaction } from 'src/app/models/Transaction';

interface TransactionListResult {
  transactions: any[];
  pagination: {
    total: number;
    page: number;
    perPage: number;
  }
}

interface CategoryListResult {
  categories: Category[];
  pagination: {
    total: number;
    page: number;
    perPage: number;
  }
}

interface Category {
  id: number;
  name: string;
  children: Category[];
}

@Injectable({
providedIn: 'root'
})
export class TransactionService {

  cachedCategories: Category[] = [];
  constructor(private http: HttpClient, private authService: AuthService) {

  }

  /*
    Get all institutions
  */
  async getAllTransactions(projectId: string): Promise<TransactionListResult> {
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    return this.http.get(environment.systemAPI + '/' + projectId + '/transactions', {headers: headers}).toPromise() as Promise<TransactionListResult>;
  } 

  /*
    Get Transactions
  */
  async getTransactions(projectId: string, opts: any = null): Promise<TransactionListResult> {
    
    let page = 1;
    let perPage = 100;
    let accountIds = [];

    if(opts?.page) page = opts.page;
    if(opts?.perPage) perPage = opts.perPage;
    if(opts?.accountIds) accountIds = opts.accountIds;
   
    let token = await this.authService.getAuthToken();
    let headers = {
        'Authorization': token
    };
    let params = {
      page: page,
      perPage: perPage,
      accounts: accountIds.join(","),
    };
    return this.http.get(environment.systemAPI + '/' + projectId + '/transactions', {headers: headers, params: params}).toPromise() as Promise<TransactionListResult>;
  }

   /*
    Get Categories
  */
    async getCategories(projectId: string, opts: any = null): Promise<CategoryListResult> {
    
      let page = 1;
      let perPage = 100;
      let categoryIds = [];
  
      if(opts?.page) page = opts.page;
      if(opts?.perPage) perPage = opts.perPage;
      if(opts?.categoryIds) categoryIds = opts.categoryIds;
     
      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      let params = {
        page: page,
        perPage: perPage,
        categories: categoryIds.join(","),
      };
      return this.http.get(environment.systemAPI + '/' + projectId + '/categories', {headers: headers, params: params}).toPromise() as Promise<any>;
    }

  /*
    Get Categories as Options
  */
  async getCategoriesAsOptions(projectId: string): Promise<any> {
    
    let categories = this.cachedCategories;
    if(!categories.length) {
      let result = await this.getCategories(projectId);
      categories = result.categories;
      this.cachedCategories = categories;
    }

    let categoryOptions = [];
    categories.forEach((category) => {
      categoryOptions.push({
        title: category.name,
        value: category.id
      });
    });

    return categoryOptions;
  }

  /*
    make a transaction flat, so only one level deep
  */
  flattenTransaction(transaction: Transaction): any {
    // for category use .name, parrentCategory use .parentCategory.name
    const flatTransaction = {} as any;
    Object.keys(transaction).forEach((key) => {
      if(key == 'category' && transaction.category) flatTransaction.category = transaction.category.name;
      else flatTransaction[key] = transaction[key];
    });
    console.log('flatTransaction', flatTransaction);
    return flatTransaction;
  }

}
