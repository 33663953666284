import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';

import { Project } from '../../models/Project';
import { ProjectService } from '../../providers/project-service/project-service';

import { ProjectSwitchPopoverComponent } from '../project-switch-popover/project-switch-popover.component';
import { CreateProjectPage } from '../../pages/create-project/create-project.page';

@Component({
  selector: 'project-switch',
  templateUrl: './project-switch.component.html',
  styleUrls: ['./project-switch.component.scss'],
})
export class ProjectSwitchComponent implements OnInit {

  @ViewChild('inner',{static: false}) innerEle: ElementRef;

  @Input() creation: boolean = true;

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController,
    public projectService: ProjectService) {}

  ngOnInit() {}

  async presentPopover(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();

    let fakeEv = JSON.parse(JSON.stringify(ev));
    fakeEv.target = this.innerEle.nativeElement;

    const popover = await this.popoverController.create({
      component: ProjectSwitchPopoverComponent,
      event: fakeEv,
      translucent: true,
      cssClass: (this.creation ? 'project-switch-popover' : 'project-switch-popover no-creation'),
      componentProps: {
        creation: this.creation
      }
    });
    await popover.present();
    return false;
  }

  async createProject(){
    const modal = await this.modalController.create({
      component: CreateProjectPage,
      cssClass: 'small-modal'
    });
    await modal.present();
  }

}
