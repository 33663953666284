import { Component, Input, OnInit } from '@angular/core';
import { IntegrationService } from 'src/app/providers/integration-service/integration-service';

@Component({
  selector: 'integration-field-mapper',
  templateUrl: './integration-field-mapper.component.html',
  styleUrls: ['./integration-field-mapper.component.scss'],
})
export class IntegrationFieldMapperComponent implements OnInit {

  _foreignOptions: any = [];
  @Input() set foreignOptions(value: any) {
    this._foreignOptions = value;
  }

  _localOptions: any = [];
  @Input() set localOptions(value: any) {
    this._localOptions = value;
  }

  _matchings: any = [];
  @Input() set matchings(value: any) {
    console.log(value);
    this._matchings = value;
  }

  constructor(private integrationService: IntegrationService) { }

  ngOnInit() {}

  onTargetChange(foreignOption, localOption){
    this._matchings[foreignOption.value] = localOption.value;
  }

}
