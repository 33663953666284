import { Injectable, Inject } from '@angular/core';

import { AuthService } from './../auth-service/auth-service';

import * as FAQ_JSON from '../../../assets/i18n/faq.json';
import { FAQ } from 'src/app/models/FAQ';

@Injectable({
    providedIn: 'root'
})
export class FAQService {

  faq: FAQ[] = [];

  constructor(
    private auth: AuthService,
  ){
    // @ts-ignore
    FAQ_JSON.default.forEach((rawFaq)=>{
        let faq = new FAQ();
        faq.question = rawFaq.question.de;
        faq.answer = rawFaq.answer.de;
        faq.category = rawFaq.category.split(",");
        faq.category.forEach((category, index)=>{
          faq.category[index] = category.trim().toLowerCase();
        });
        faq.link = rawFaq.link;
        this.faq.push(faq);
      });
    console.log(this.faq);
  }

  /*
    Get the Faqs, either all or category based
  */
  getFAQS(category?: string): FAQ[]{
    if(category){
      return this.faq.filter((faq)=>{
        return faq.category.indexOf(category.toLocaleLowerCase()) > -1;
      });
    }
    return this.faq;
  }
  

}
