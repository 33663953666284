import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateMiddle'
})
export class truncateMiddlePipe implements PipeTransform {

  transform(apiKey: string, startLength: number = 4, endLength: number = 4): string {
    if (!apiKey) {
      return '';
    }

    if (apiKey.length <= startLength + endLength) {
      return apiKey;
    }

    const start = apiKey.substring(0, startLength);
    const end = apiKey.substring(apiKey.length - endLength);
    return `${start}...${end}`;
  }

}