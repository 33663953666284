import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';

import { Project } from './../../models/Project';
import { ProjectMember } from './../../models/ProjectMember';

import { AuthService } from './../auth-service/auth-service';
import { NotificationService } from './../notification-service/notification-service';

import { map, take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';

import { environment } from './../../../environments/environment';
import { InstitutionService } from '../institution-service/institution-service';
import { Account } from 'src/app/models/Account';

interface PlatformCheck {
  projects: boolean;
  users: boolean;
  detail: {
      p: number;
      u: number;
      fiu: number;
  }
};

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    projectCollection: AngularFirestoreCollection <Project>;

    projects: Observable<Project[]>;
    
    currentProject: Project;

    currentProjectId: string;
    currentProjectEnrichData: any = {};
    currentProjectAccounts: any[];

    projectSubscription$: Subscription;

    ready: boolean = false;
    loading: boolean = true;

   constructor(private afs: AngularFirestore,
               private auth: AuthService,
               private notificationService: NotificationService,
               private http: HttpClient,
               private institutionService: InstitutionService
                ) {
     this.initStore();
    }

    initStore(): Promise<boolean> {
      return new Promise((resolve,reject)=>{
        this.auth.getUid().then((userId)=>{
           this.projectCollection = this.afs.collection<Project>('projects', 
              ref => ref.where('team', 'array-contains', userId)
              );
           this.projects = this.projectCollection.snapshotChanges().pipe(
              map(actions => {
                return actions.map(a => {
                  let data = a.payload.doc.data();
                  // @ts-ignore
                  const id = a.payload.doc.id; 
                  data = this.convertProjectTimestamps(data);
                  return { id, ...data };
                });
              })
          );
          this.loading = false;
          this.ready = true;
          return resolve(true);
        });
      });
    }

    addProject(item: Project) {
        let itemCleared = JSON.parse(JSON.stringify(item));
        itemCleared.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        // delete properties, which came from our vault
        itemCleared = this.clearItem(itemCleared);

        return this.projectCollection.add(itemCleared);
    }

    async updateProjectById(id: string, item: Project) {
        let itemCleared = JSON.parse(JSON.stringify(item));

        // save secrets separately into the vault
        await this.saveProjectSecrets(itemCleared);

        // delete properties, which came from our vault
        itemCleared = this.clearItem(itemCleared);

        return this.projectCollection.doc(id).set(itemCleared);
    }

  /*
    encryptes & saves secrets into the vault
  */
  saveProjectSecrets(project: Project) {
    return new Promise(async (resolve, reject) => {
      // check if a integration has a secret, which needs to be saved
      let foundSecrets = false;
      if (project.integrations) {
        project.integrations.forEach((integration) => {
          if (integration.secrets) {
            foundSecrets = true;
          }
        });
      }
      if (!foundSecrets) return resolve(true);

      // access api to get enriched data
      let token = await this.auth.getAuthToken();
      let headers = {
        'Authorization': token
      };

      let saveRes = await this.http.post(environment.systemAPI + '/' + project.id + '/update', project, { headers: headers }).toPromise();
      return resolve(true);
    });
  }

    getProjects(): Observable<Project[]> {
      return this.projectCollection.snapshotChanges().pipe(
        map((snapshots) =>
          snapshots.map((snapshot) => {
            const id = snapshot.payload.doc.id;
            const data = snapshot.payload.doc.data();
            return { id, ...data };
          })
        )
      );
    }
    getProjectsProm(): Promise<Project[]> {
      return this.projectCollection.get().toPromise().then((snapshot) => {
        return snapshot.docs.map((doc) => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data };
        });
      });
    }
    getProjectById(id: string): Observable<Project>{
        return this.projectCollection.doc<Project>(id).valueChanges().pipe(
        take(1),
        map(val => {
          val.id = id;
          return val
        })
      );
    }
    getProjectByIdSub(id: string): Observable<Project>{
      return this.projectCollection.doc<Project>(id).valueChanges().pipe(
        map(val => {
          val.id = id;
          return val
        })
      );
    }

    deleteProjectById(id: string) {
      return new Promise( async (resolve,reject)=>{
        if(!id) return reject('no project selected');
        // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        return resolve(this.http.delete(environment.systemAPI + '/'+id+'/delete', {headers: headers}).toPromise());
       }
      );
    }

    /*
      set current active proejct
    */
    setCurrentProject(projectId): Promise<any>  {

      if(this.currentProject && this.currentProject.id == projectId) return Promise.resolve(this.currentProject);

      this.loading = true;
      this.currentProject = null;
      this.currentProjectEnrichData = {
        isFilled: true
      };
      this.currentProjectAccounts = [];
      this.currentProjectId = projectId;

      return new Promise((resolve,reject)=> {

        if(this.projectSubscription$) this.projectSubscription$.unsubscribe();

        this.getProjectById(projectId)
        .toPromise().then((project)=>{

          // add default values
          if(!project.integrations) project.integrations = [];
          if(!project.apiKeys) project.apiKeys = [];
          if(!project.webhooks) project.webhooks = []; 

          // enrich project
          this.enrichProject(project).then((completeproject)=>{
            this.loading = false;

            // remove vault properties
            let clearedProject = this.clearItem(project);

            // get the diff between the old and the new project obj
            let diff = Object.keys(completeproject).filter(k => completeproject[k] !== clearedProject[k]);
            diff.forEach((key)=>{
              this.currentProjectEnrichData[key] = completeproject[key];
            });

            // convert timestamps
            completeproject = this.convertProjectTimestamps(completeproject);

            // set current project
            this.currentProject = completeproject;

            // update the project subscription  this.projectSubscription$
            if(this.projectSubscription$) this.projectSubscription$.unsubscribe();
            this.projectSubscription$ = this.getProjectByIdSub(projectId).subscribe((project)=>{
              
              // add the static enrich data and override project data
              if(this.currentProjectEnrichData) project = {...project, ...this.currentProjectEnrichData};

              // add default values
              if(!project.integrations) project.integrations = [];
              if(!project.apiKeys) project.apiKeys = [];
              if(!project.webhooks) project.webhooks = [];

              
              // convert timestamps
              project = this.convertProjectTimestamps(project);
    
              // set as current Project
              this.currentProject = project;
              console.log("*** ProjectUpdate ***");
            });

            return resolve(this.currentProject);
          }).catch((e)=> { 
            this.loading = false;
            this.currentProject = project;
            this.notificationService.addNotification("Da ist was schiefgelaufen auf unserer Seite. Versuche es später erneut.","system-error");
            return reject(e); });
        }).catch((e)=> { 
          this.loading = false;
          return reject(e); });
        });

    }
    /*
      get current active proejct
    */
    getCurrentProject(): Project {
      return this.currentProject;
    }
    getCurrentProjectId(): string {
      return this.currentProjectId;
    }

 
    /*
      get last opened project
    */
    getLastOpenedProject(): Promise<Project> {
      return new Promise((resolve,reject)=>{
        this.projects
        .pipe(
          take(1)
        ).toPromise().then((res)=>{
          return resolve((Array.isArray(res) ? res[0] : res ));
        }).catch((e)=> { return reject(e); });
      });
    }

    /*
      clear item
      don't save data here, which come from the vault
    */
    clearItem(item: Project){
      // delete properties, which came from our vault
      delete item.limits;
      delete item.features;
      delete item.subscription;
      delete item.isConnected;
      delete item.isFilled;
      delete item.trialEnd;
      delete item.subscriptionEnd;
      delete item.subscriptionPeriodEnd;
      delete item.subscriptionCancelAt;
      delete item.subscriptionInterval;
      delete item.subscriptionPeriodStart;
      delete item.subscriptionStart;
      delete item.isSubscribed;
      delete item.isCanceled;
      delete item.isExclusive;
      delete item.status;
      delete item.usage;

      if(item.integrations){
        for(let i=0; i<item.integrations.length; i++){
          if(!item.integrations[i].secrets) continue;
          delete item.integrations[i].secrets;
        }
      }

      return item;
    }

    /*
      get enriched project
      gets limits and encrypted secrets
    */
    enrichProject(project: Project): Promise<Project> {
      return new Promise(async (resolve,reject)=>{
        if(!project) return reject('no project');
        // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        try {
          var fullProject = await this.http.get(environment.systemAPI + '/'+project.id+'/info', {headers: headers}).toPromise();
        } catch(e) {
          return reject(e);
        }
        if(!fullProject) return reject('error enriching project');

        // merge project into full project
        let enrichedProject = Object.assign(fullProject, this.clearItem(project));

        // get accounts and cache them
        let accounts = await this.institutionService.getAccounts(project.id);
        this.currentProjectAccounts = accounts.accounts;

        return resolve(enrichedProject);
      });
    }

    /*
      get project limits
    */
    getProjectLimits(): Promise<any> {
      return new Promise((resolve,reject)=>{
        let project = this.getCurrentProject();
        if(!project) return reject('no project selected');
        let limits = project.limits;
        if(!limits.bankAccounts) limits.bankAccounts = 5;
        if(!limits.teamMembers) limits.teamMembers = 3;
        if(!limits.integrations) limits.integrations = 3;
        return resolve(limits);
      });
    }

    /*
      get members of a project
    */
    getProjectMembers(project: Project): Promise<any> {
      return new Promise( async (resolve,reject)=>{
        if(!project) return reject('no project selected');
              // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        let members = await this.http.get(environment.systemAPI + '/'+project.id+'/members', {headers: headers}).toPromise();
        return resolve(members as ProjectMember);
      });
    }

    removeMember(project: Project, uid: string): Promise<any> {
      return new Promise( async (resolve,reject)=>{
        if(!project) return reject('no project selected');
        // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        return resolve(this.http.delete(environment.systemAPI + '/'+project.id+'/members/'+uid, {headers: headers}).toPromise());
       }
      );
    }
    /*
      Inivtations
    */
    inviteMember(project: Project, email: string, role: string): Promise<any> {
      return new Promise( async (resolve,reject)=>{
        if(!project) return reject('no project selected');
        // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        return resolve(this.http.post(environment.systemAPI + '/'+project.id+'/invites', {email: email, role: role}, {headers: headers}).toPromise());
      });
    }

    resendInvite(project: Project, inviteCode: string): Promise<any> {
      return new Promise( async (resolve,reject)=>{
        if(!project) return reject('no project selected');
        // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        return resolve(this.http.post(environment.systemAPI + '/'+project.id+'/invites/'+inviteCode+'/resend', {}, {headers: headers}).toPromise());
      });
    }

    deleteInvite(project: Project, inviteCode: string): Promise<any> {
      return new Promise( async (resolve,reject)=>{
        if(!project) return reject('no project selected');
        // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        return resolve(this.http.delete(environment.systemAPI + '/'+project.id+'/invites/'+inviteCode, {headers: headers}).toPromise());
      });
    }
    
    getInvitesOfUser(): Promise<any> {
      return new Promise( async (resolve,reject)=>{
        // access api to get enriched data
        let token = await this.auth.getAuthToken();
        let headers = {
            'Authorization': token
        };
        return resolve(this.http.get(environment.systemAPI + '/invites', {headers: headers}).toPromise());
      });
    }

    /*
      get members of a project
    */
  triggerAction(project: Project, action: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!project) return reject('no project selected');
      // access api to get enriched data
      let token = await this.auth.getAuthToken();
      let headers = {
        'Authorization': token
      };
      let members = await this.http.post(environment.systemAPI + '/' + project.id + '/trigger', {
        action: action
      }, { headers: headers }).toPromise();
      return resolve(members as ProjectMember);
    });
  }

    /*
      is ready
    */
    isReady(){
      return new Promise((resolve,reject)=>{
          if(this.ready){
              return resolve(true);
          } else {
            this.initStore().then((ready)=>{
              return resolve(true);
            });
          }
      });
    }

    /*
      get cached accounts
    */
    getCachedAccounts(): any[] {
      return this.currentProjectAccounts;
    }
    /*
      Update the cached accounts
    */
    async updateCachedAccounts(): Promise<any[]> {
        let project = this.getCurrentProject();
        // get accounts and cache them
        let accounts = await this.institutionService.getAccounts(project.id);
        this.currentProjectAccounts = accounts.accounts;
        return this.currentProjectAccounts;
    }

  /*
    can access features / limits
    and also user roles
  */
  canAccess(feature: string): boolean {

    if (!this.ready) return false;

    let project = this.getCurrentProject();
    if (!project) return false;

    // determine userrole
    let currentUser = this.auth.currentUser.uid;
    if (!currentUser) return false;

    let userRole = project.members[currentUser]?.role;
    if (project.owner.includes(currentUser)) userRole = 'owner';
    if(!userRole) return false;

    if (feature == 'inviteMembers') {
      if (userRole == 'owner') return true;
      return false;
    }
    if (feature == 'editWorkspace') {
      if (userRole == 'owner') return true;
      return false;
    }
    if (feature == 'manageMembership') {
      if (userRole == 'owner') return true;
      return false;
    }


    /*
      Features locked by backend
    */
    if (feature == 'bankAccounts') {
      if (!project.features) return false;
      if (project.features.bankAccounts 
        && (userRole == "owner" || userRole == "banking" || userRole == "member")
        ) return true;
      return false;
    }

    if (feature == 'integrations') {
      if (!project.features) return false;
      if (project.features.integrations 
        && (userRole == "owner" || userRole == "integrator" || userRole == "member")
        ) return true;
      return false;
    }

    if (feature == 'api') {
      if (!project.features) return false;
      if (project.features.api 
        && (userRole == "owner" || userRole == "integrator" || userRole == "member")
        ) return true;
      return false;
    }

    if (feature == 'webhooks') {
      if (!project.features) return false;
      if (project.features.webhooks 
        && (userRole == "owner" || userRole == "integrator" || userRole == "member")
        ) return true;
      return false; // debug
    }

    return false;
  }

  /*
    Project Active 
    (Check if subscription or trial is active)
  */
  isProjectActive(): boolean {
    let project = this.getCurrentProject();
    if(!project) return false;

    var active = false;

    // check if exclusive
    if(project.isExclusive) return true;

    // check if trial and trialEnd is not in the past
    const trialEnd = project.trialEnd ? new Date(project.trialEnd) : null;
    if(project.subscription == "trial" && trialEnd > new Date()) return true;

    // check if subscription and subscriptionEnd is not in the past
    const subEnd = project.subscriptionPeriodEnd ? new Date(project.subscriptionPeriodEnd) : null;
    if(project.isSubscribed 
      && (!subEnd 
        || (subEnd > new Date()) )
      ) return true;

    return active;
  }

  /*
    Platform availbillity check
  */
  getPlatformStatus(): Promise<PlatformCheck> {
    return new Promise(async (resolve, reject) => {
        let token = await this.auth.getAuthToken();
      let headers = {
        'Authorization': token
      };
      let res = await this.http.get(environment.systemAPI + '/platformCheck', { headers: headers }).toPromise();
      return resolve(res as PlatformCheck);
    });
  }

    /*
      Convert Firestore Timestamp to Date
    */
    convertTimestampToDate(timestamp: any): Date {
      if(!timestamp) return null;
      if(timestamp instanceof Date) return timestamp;
      let stamp = timestamp;
      if(stamp.seconds) return new Date(stamp.seconds * 1000);
      let parsedDate = new Date(stamp);
      if(parsedDate.getTime() > 0) return parsedDate;
      return stamp.toDate();
    }

    convertProjectTimestamps(project: Project): Project{
      // convert timestamps
      if (project.createdAt) project.createdAt = this.convertTimestampToDate(project.createdAt);
      if (project.lastImport) project.lastImport = this.convertTimestampToDate(project.lastImport);
      if (project.importLog?.date) project.importLog.date = this.convertTimestampToDate(project.importLog.date);

      if(project.integrations){
        project.integrations.forEach((integration: any, ind: number) => {
          if (integration.lastSync) project.integrations[ind].lastSync = this.convertTimestampToDate(integration.lastSync);
          if (integration.nextSync) project.integrations[ind].nextSync = this.convertTimestampToDate(integration.nextSync);

          if(integration.syncLog && integration.syncLog.date) project.integrations[ind].syncLog.date = this.convertTimestampToDate(integration.syncLog.date);

        });
      }

      return project;
    }

}
