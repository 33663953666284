import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../../providers/notification-service/notification-service';
import { AuthService } from '../../providers/auth-service/auth-service';

@Component({
  selector: 'notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss'],
})
export class NotificationBannerComponent implements OnInit {

  constructor(
    private notificationService: NotificationService,
    private auth: AuthService) { }

  ngOnInit() {}

  resendVerifyMail(){
    this.auth.sendVerificationMail();
    this.notificationService.addNotification("Email sent! ✔️ Just click the link in the email to verify your account.");
    this.notificationService.dismiss();
    return false;
  }

  dismiss(){
    this.notificationService.dismiss();
    return false;
  }

}
