import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '../project-service/project-service';
import { AuthService } from '../auth-service/auth-service';
import { Integration } from 'src/app/models/Integration';
import { TranslationBridge } from '../translation-bridge/translation-bridge';


const INTEGRATIONS = [
 {
    name: 'Ninox',
    icon: 'image:ninox.png',
    type: 'ninoxTableSync',
    description: 'Verbinde deine Bankkonten mit Ninox und synchronisiere deine Transaktionen und Bankkonten automatisch.',
 }
];

interface IntegrationListItem {
  name: string;
  icon: string;
  type: string;
  description: string;
};

const ACCOUNT_MAPPINGS = [
  { 
    key: "id",
    title: "ID",
    description: "Internal identifier",
    example: "1",
    type: "number",
    group: "General",
    synonyms: [
      "id",
      "bankid",
      "kontoid",
      "identifikation",
      "identifier",
      "internalId",
      "kennung",
      "ref"
    ],
  },
  { 
    key: "bankConnectionId",
    title: "Bank Connection ID",
    description: "Identifier for the bank connection",
    example: "1",
    type: "number",
    group: "General",
    synonyms: [
      "bankConnectionId",
      "connectionId",
      "bankId",
      "bankIdentifier"
    ],
  },
  { 
    key: "accountName",
    title: "Account Name",
    description: "Name of the account",
    example: "Testaccount",
    type: "string",
    group: "Account",
    synonyms: [
      "accountName",
      "name",
      "accountTitle",
      "accountLabel"
    ],
  },
  { 
    key: "iban",
    title: "IBAN",
    description: "International Bank Account Number",
    example: "DE89370400440532013000",
    type: "string",
    group: "Account",
    synonyms: [
      "iban",
      "bankAccountNumber",
      "accountNumber"
    ],
  },
  { 
    key: "accountNumber",
    title: "Account Number",
    description: "Account number",
    example: "12345678",
    type: "string",
    group: "Account",
    synonyms: [
      "accountNumber",
      "number"
    ],
  },
  { 
    key: "subAccountNumber",
    title: "Subaccount Number",
    description: "Subaccount number",
    example: "1234",
    type: "string",
    group: "Account",
    synonyms: [
      "subAccountNumber",
      "subNumber"
    ],
  },
  { 
    key: "accountHolderName",
    title: "Account Holder Name",
    description: "Name of the account holder",
    example: "Herr Max Mustermann",
    type: "string",
    group: "Account",
    synonyms: [
      "accountHolderName",
      "holderName",
      "name",
      "accountOwner"
    ],
  },
  { 
    key: "accountHolderId",
    title: "Account Holder ID",
    description: "Identifier of the account holder",
    example: "XXXXX",
    type: "string",
    group: "Account",
    synonyms: [
      "accountHolderId",
      "holderId",
      "id",
      "ownerId"
    ],
  },
  { 
    key: "accountCurrency",
    title: "Account Currency",
    description: "Currency of the account",
    example: "EUR",
    type: "string",
    group: "Balance",
    synonyms: [
      "accountCurrency",
      "currency",
      "currencyCode",
      "währung",
    ],
  },
  { 
    key: "accountType",
    title: "Account Type",
    description: "Type of the account",
    example: "Checking",
    type: "string",
    group: "Account",
    synonyms: [
      "accountType",
      "type"
    ],
  },
  { 
    key: "bic",
    title: "BIC",
    description: "Bank Identifier Code",
    example: "DEBANK12345",
    type: "string",
    group: "Bank",
    synonyms: [
      "bic",
      "bankAccountCode",
      "bankCode"
    ],
  },
  { 
    key: "blz",
    title: "BLZ",
    description: "Bankleitzahl",
    example: "BANK12345",
    type: "string",
    group: "Bank",
    synonyms: [
      "blz",
      "bankleitzahl",
      "bankCode",
    ],
  },
  { 
    key: "bankName",
    title: "Bank Name",
    description: "Name of the bank",
    example: "Example Bank",
    type: "string",
    group: "Bank",
    synonyms: [
      "bankName",
      "bank",
      "nameOfBank"
    ],
  },
  { 
    key: "bankGroupName",
    title: "Bank Name (Group)",
    description: "More general name of the bank",
    example: "Example Bank",
    type: "string",
    group: "Bank",
    synonyms: [
      "bankGroupName",
      "bankGroup",
      "gruppe"
    ],
  },
  { 
    key: "balance",
    title: "Balance",
    description: "Current balance of the account",
    example: "99.99",
    type: "number",
    group: "Balance",
    synonyms: [
      "balance",
      "accountBalance"
    ],
  },
  { 
    key: "overdraft",
    title: "Overdraft",
    description: "Current overdraft amount",
    example: "99.99",
    type: "number",
    group: "Balance",
    synonyms: [
      "overdraft",
      "overdraftAmount",
      "negativeBalance"
    ],
  },
  { 
    key: "overdraftLimit",
    title: "Overdraft Limit",
    description: "Maximum allowed overdraft limit",
    example: "99.99",
    type: "number",
    group: "Balance",
    synonyms: [
      "overdraftLimit",
      "maximumOverdraft",
      "creditLimit"
    ],
  },
  { 
    key: "availableFunds",
    title: "Available Funds",
    description: "Funds available for withdrawal or use",
    example: "99.99",
    type: "number",
    group: "Balance",
    synonyms: [
      "availableFunds",
      "funds",
      "availableBalance"
    ],
  },
  {
    key: "transactionRef",
    title: "Transaction Table Reference",
    description: "A reference to the transactions table to link transactions to this account",
    example: "",
    type: "ref",
    group: "Relations",
    synonyms: [
      "transactionRef",
      "transactions",
      "transaktionen",
      "kontobewegungen",
      "kontobewegung",
      "buchungen",
      "buchung",
      "transactionsTable",
      "kontoauszug",
      "kontoauszüge",
      "kontoauszugs",
      "umsätze",
    ],
  },  
];

const TRANSACTION_MAPPINGS = [
  { 
    key: "id",
    title: "ID",
    description: "Internal Transaction identifier",
    example: "1234567",
    type: "string",
    group: "General",
    synonyms: [
      "id",
      "transactionId",
      "kennung",
      "identifikation",
      "identifier",
      "internalId"
    ],
  },
  { 
    key: "amount",
    title: "Amount",
    description: "Transaction amount",
    example: "104,99",
    type: "number",
    group: "Amount",
    synonyms: [
      "betrag",
      "summe",
      "amount",
      "menge",
      "saldo",
      "ausgabe",
      "einnahme",
      "buchung"
    ],
  },
  { 
    key: "currency",
    title: "Currency",
    description: "Transaction currency in ISO 4217 format.This field can be null if not explicitly provided the bank. In this case it can be assumed as accounts currency.",
    example: "EUR",
    type: "string",
    group: "Amount",
    synonyms: [
      "währung",
      "currency",
      "waehrung",
      "EUR",
    ],
  },
  { 
    key: "amount+currency",
    title: "Amount combined with Currency",
    description: "Transaction amount combined with the currency of this transaction",
    example: "104,99 EUR",
    group: "Amount",
    type: "string",
    synonyms: [

    ],
  },
  { 
    key: "purpose",
    title: "Purpose",
    description: "Transaction purpose",
    example: "Invoice 1234",
    type: "string",
    group: "General",
    synonyms: [
      "purpose",
      "zweck",
      "verwendungszweck",
      "angabe",
      "verwendung"
    ],
  },
  {
    key: "category",
    title: "Category",
    description: "AI categorization of the transaction",
    example: "Gebühren, Miete, Abonnement, Büromaterial, etc.",
    type: "string",
    group: "Artificial Intelligence",
    synonyms: [
      "category",
      "kategorie",
    ],
  },
  {
    key: "parentCategory",
    title: "Parent Category",
    description: "Broader, less detailed AI categorization of the transaction",
    example: "Einnahmen, Bank & Kredit, Shopping & Unterhaltung, etc.",
    type: "string",
    group: "Artificial Intelligence",
    synonyms: [
      "parentcategory",
      "hauptkategorie",
      "klassifikation"
    ],
  },
  {
    key: "type",
    title: "Type",
    description: 'Transaction type, according to the bank. If set, this will contain a German term that you can display to the user. Some examples of common values are: "Lastschrift", "Auslandsüberweisung", "Gebühren", "Zinsen".',
    example: "Lastschrift",
    type: "string",
    group: "General",
    synonyms: [
      "type",
      "art",
    ],
  },
  {
    key: "valueDate",
    title: "Value Date",
    description: "Transaction value date",
    example: "2020-01-01",
    type: "date",
    group: "Dates",
    synonyms: [
      "valueDate",
      "datum",
      "buchungsdatum",
      "buchung",
      "buchungszeitpunkt",
      "buchungszeit",
    ],
  },
  {
    key: "accountId",
    title: "Account ID",
    description: "Account ID of the account this transaction belongs to",
    example: "1234567",
    type: "string",
    group: "Account",
    synonyms: [
      "accountId",
      "kontoId",
      "konto",
      "account",
      "kontonummer",
      "kontoid",
    ],
  },
  {
    key: "accountName",
    title: "Account Name",
    description: "Accountholder Name of the current account",
    example: "Meine Gesellschaft mbH",
    type: "string",
    group: "Account",
    synonyms: [
      "accountName",
      "kontoinhaber",
      "konto",
      "accountOwner",
      "ownAccountName"
    ],
  },
  {
    key: "accountNumber",
    title: "Account Number",
    description: "Accountnumber of the current account",
    example: "",
    type: "string",
    group: "Account",
    synonyms: [
      "accountNumber",
    ],
  },
  {
    key: "accountIban",
    title: "Account IBAN",
    description: "IBAN of the current account",
    example: "",
    type: "string",
    group: "Account",
    synonyms: [
      "iban",
      "accountIban",
      "accountIbanNumber",
      "ownAccountIban",
    ],
  },
  {
    key: "accountBlz",
    title: "Account BLZ",
    description: "BLZ of the current account",
    example: "",
    type: "string",
    group: "Account",
    synonyms: [
      "blz",
      "accountBlz",
      "accountBlzNumber",
      "ownAccountBlz",
    ],
  },  
  {
    key: "accountBIC",
    title: "Account BIC",
    description: "BIC of the current account",
    example: "",
    type: "string",
    group: "Account",
    synonyms: [
      "bic",
      "accountBic",
      "accountBicNumber",
      "ownAccountBic",
    ],
  },  
  {
    key: "accountBankName",
    title: "Account Bank Name",
    description: "Bankname of the current account",
    example: "",
    type: "string",
    group: "Account",
    synonyms: [
      "bic",
      "accountBic",
      "accountBicNumber",
      "ownAccountBic",
    ],
  },  
  {
    key: "counterpartName",
    title: "Counterpart Name",
    description: "Name of the counterpart",
    example: "Max Mustermann Gmbh",
    type: "string",
    group: "Counterpart",
    synonyms: [
      "counterpartName",
      "counterpart",
      "counterpartAccount",
      "counterpartAccountName",
      "counterpartAccountNumber",
      "counterpartAccountOwner",
      "counterpartAccountHolder",
      "counterpartAccountHolderName",
    ],
  },
  {
    key: "counterpartAccountNumber",
    title: "Counterpart Account Number",
    description: "Accountnumber of the counterpart",
    example: "",
    type: "string",
    group: "Counterpart",
    synonyms: [
      "counterpartName",
      "counterpart",
      "counterpartAccount",
      "counterpartAccountName",
      "counterpartAccountNumber",
      "counterpartAccountOwner",
      "counterpartAccountHolder",
      "counterpartAccountHolderName",
    ],
  },
  {
    key: "counterpartIban",
    title: "Counterpart IBAN Number",
    description: "Accountnumber of the counterpart",
    example: "",
    type: "string",
    group: "Counterpart",
    synonyms: [
      "empfänger",
      "gegenkonto",
      "gegenkontoiban",
      "gegenkontoibannummer",
      "gegenkontoiban",
      "empfängeriban",
    ],
  },
  {
    key: "counterpartBlz",
    title: "Counterpart BLZ Number",
    description: "BLZ of the counterpart",
    example: "",
    type: "string",
    group: "Counterpart",
    synonyms: [

    ],
  },
  {
    key: "counterpartBic",
    title: "Counterpart BIC Number",
    description: "BIC of the counterpart",
    example: "",
    type: "string",
    group: "Counterpart",
    synonyms: [

    ],
  },
  {
    key: "counterpartBankName",
    title: "Counterpart Bank Name",
    description: "BIC of the counterpart",
    example: "",
    type: "string",
    group: "Counterpart",
    synonyms: [

    ],
  },
  {
    key: "importDate",
    title: "Import Date",
    description: "Date and Time when the transaction was imported",
    example: "2020-01-01T12:00:00Z",
    type: "datetime",
    group: "Dates",
    synonyms: [
      "importdate",
      "importdatum",
      "import-datum",
      "import-zeitpunkt",
      "import-zeit",
    ],
  },
  {
    key: "syncDate",
    title: "Sync Date",
    description: "Date and Time when the transaction was synced with the current integration",
    example: "2020-01-01T12:00:00Z",
    type: "datetime",
    group: "Dates",
    synonyms: [
      "syncdate",
      "syncronisationsdatum",
      "syncronisationdate",
      "syncronisationdatum",
    ],
  },  {
    key: "accountRef",
    title: "Account Table Reference",
    description: "A reference to the account table record of the current account",
    example: "",
    type: "ref",
    group: "Relations",
    synonyms: [
      "bic",
      "accountBic",
      "accountBicNumber",
      "ownAccountBic",
    ],
  },  
];

const TIME_INTERVALS = [{
  title: "INTEGRATION_SYNC_INTERVAL_DAILY",
  subtitle: "INTEGRATION_SYNC_INTERVAL_DAILY_INFO",
  value: "daily",
  disabled: false,
},{
  title: "INTEGRATION_SYNC_INTERVAL_WEEKLY",
  subtitle: "INTEGRATION_SYNC_INTERVAL_WEEKLY_INFO",
  value: "weekly",
  disabled: false,
},{
  title: "INTEGRATION_SYNC_INTERVAL_MONTHLY",
  subtitle: "INTEGRATION_SYNC_INTERVAL_MONTHLY_INFO",
  value: "monthly",
  disabled: false,
}];


@Injectable({
  providedIn: 'root'
})
export class IntegrationService {


  constructor(
    private http: HttpClient, 
    private projectService: ProjectService, 
    private authService: AuthService,
    private translate: TranslationBridge) {

  }

  public getIntegrations(){
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      return resolve(project.integrations);
    });
  }

  public getIntegrationSecrets(integrationId: string){
    return new Promise(async (resolve, reject) => {
    let project = this.projectService.getCurrentProject();
    if(!project) return reject("No active project found");
    let index = project.integrations.findIndex((int) => {
      return int.id == integrationId;
    });

    if(index == -1) return reject("Integration not found");

        let token = await this.authService.getAuthToken();
        let headers = {
          'Authorization': token
        };
  
        let readRes = await this.http.get(environment.systemAPI + '/' + project.id + '/integrations/' + integrationId + '/secrets', { headers: headers }).toPromise();
        return resolve(readRes);
    });
  }

  public addIntegration(integrationType: string): Promise<Integration>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      if(project.integrations.length >= project.limits.integrations){
        return reject("You have reached the maximum number of integrations for this project.");
      } 

      let meta = INTEGRATIONS.find((integration) => {
        return integration.type == integrationType;
      });

      let integration = new Integration();
      integration = {
        id: new Date().getTime().toString(),
        type: integrationType,
        name: meta.name,
        setupStep: "setup",
        config: this.defaultConfig(integrationType),
        secrets: this.defaultSecrets(integrationType),
        active: false,
        interval: "",
      };

      project.integrations.push(integration);

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(integration);
      }).catch((err) => {
        return reject(err);
      });

    });
  }

  public updateIntegration(integration: Integration): Promise<boolean>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      let index = project.integrations.findIndex((int) => {
        return int.id == integration.id;
      });

      if(index == -1) return reject("Integration not found");

      project.integrations[index] = integration;

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });   
    });
  }

  public deleteIntegration(integrationId: string): Promise<boolean>{
    return new Promise((resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      
      let index = project.integrations.findIndex((integration) => {
        return integration.id == integrationId;
      });
    
      if(index == -1) return reject("Integration not found");

      project.integrations.splice(index, 1);

      this.projectService.updateProjectById(project.id,project).then(() => {
        return resolve(true);
      }).catch((err) => {
        return reject(err);
      });
  
    });
  }

  public manualSyncIntegration(integrationId: string): Promise<boolean>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      
      let index = project.integrations.findIndex((integration) => {
        return integration.id == integrationId;
      });

      if(index == -1) return reject("Integration not found");

      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      return resolve(this.http.post(environment.systemAPI + '/'+project.id+'/integrations/'+integrationId+'/sync', {},{headers: headers}).toPromise() as Promise<any>);
  
    });
  }
  
  public sendTestData(integrationId: string, transactions: any): Promise<boolean>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");
      
      let index = project.integrations.findIndex((integration) => {
        return integration.id == integrationId;
      });

      if(index == -1) return reject("Integration not found");

      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      
      return resolve(this.http.post(environment.systemAPI + '/'+project.id+'/integrations/'+integrationId+'/test', {
        transactions: transactions
      },{headers: headers}).toPromise() as Promise<any>);
    });    
  }

  /*
    Get Sync History
  */
  public getSyncHistory(integrationId: string, offset: number = 0, limit: number = 250): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      let index = project.integrations.findIndex((integration) => {
        return integration.id == integrationId;
      });

      if(index == -1) return reject("Integration not found");

      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      
      return resolve(this.http.get(environment.systemAPI + '/'+project.id+'/integrations/'+integrationId+'/history', {headers: headers}).toPromise() as Promise<any>);
    });
  }

  /*
    Sync Token Management
    allows to sync without login
  */
  public generateSyncToken(integrationId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      let index = project.integrations.findIndex((integration) => {
        return integration.id == integrationId;
      });

      if(index == -1) return reject("Integration not found");

      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      return resolve(this.http.post(environment.systemAPI + '/'+project.id+'/integrations/'+integrationId+'/tokengen', {},{headers: headers}).toPromise() as Promise<any>);
    });
  }

  public deleteSyncToken(integrationId: string): Promise<any>{
    return new Promise(async (resolve, reject) => {
      let project = this.projectService.getCurrentProject();
      if(!project) return reject("No active project found");

      let index = project.integrations.findIndex((integration) => {
        return integration.id == integrationId;
      });

      if(index == -1) return reject("Integration not found");

      let token = await this.authService.getAuthToken();
      let headers = {
          'Authorization': token
      };
      return resolve(this.http.post(environment.systemAPI + '/'+project.id+'/integrations/'+integrationId+'/tokengen', {},{headers: headers}).toPromise() as Promise<any>);
    });
  }

  public defaultConfig(type: string){
    if(type == "ninoxTableSync"){
      return {
        customApiUrl: "",
        team: "",
        database: "",
        tableAccounts: "",
        tableTransactions: "",
        tableCounterparts: "",
        fieldMappingAccounts: {},
        fieldMappingTransactions: {},
        fieldMappingCounterparts: {}
      };
    }
  }

  public defaultSecrets(type: string){
    if(type == "ninoxTableSync"){
      return {
        apiKey: ""
      }
    }
  }

  public getIntegrationList(): Promise<IntegrationListItem[]> {
    return new Promise((resolve, reject) => {
      return resolve(INTEGRATIONS);
    });
  }

  public getIntegrationIcon(type: string){
    let integration = INTEGRATIONS.find((integration) => {
      return integration.type == type;
    });
    return integration.icon;
  }

  public getAvailableTransactionFields(opts: any = {}): Array<any>{
    if(!opts || !opts.asOptions) return TRANSACTION_MAPPINGS;

    // give an option to ignore the field as default
    let list = [{
      key: "ignore",
      title: "Do nothing",
      description: "Ignore this field, nothing will be added.",
      example: "",
      type: "ignore",
      synonyms: []
    }, ...TRANSACTION_MAPPINGS ] as any;

    list.forEach((item) => {
      item.value = item.key;

      // turn item key from camelCase to snake_case
      const translationKey = "TRANSACTION_FIELD_"+(item.key.replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace(/ /g, '_').toUpperCase()).replace(/[^A-Z_]/g, '_');
      const translatedTitle = this.translate.get(translationKey+"_TITLE");
      const translatedDescription = this.translate.get(translationKey+"_INFO");
      const translatedExample = this.translate.get(translationKey+"_EXAMPLE");

      const groupTranslationKey = "TRANSACTION_GROUP_"+((item.group ? item.group : 'general').replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace(/ /g, '_').toUpperCase());
      const translatedGroup = this.translate.get('TRANSACTION_GROUP_'+groupTranslationKey);

      if(translatedTitle) item.title = translatedTitle;
      if(translatedDescription) item.description = translatedDescription;
      if(translatedExample) item.example = translatedExample;
      //if(translatedGroup) item.group = translatedGroup;

      item.subtitle = item.description+(item.example ? " ("+item.example+")" : "");
    });

    return list;
  }

  public getAvailableAccountFields(opts: any = {}): Array<any>{
    if(!opts || !opts.asOptions) return ACCOUNT_MAPPINGS;

    // give an option to ignore the field as default
    let list = [{
      key: "ignore",
      title: "Do nothing",
      description: "Ignore this field, nothing will be added.",
      example: "",
      type: "ignore",
      synonyms: []
    }, ...ACCOUNT_MAPPINGS ] as any;

    list.forEach((item) => {
      item.value = item.key;

      // turn item key from camelCase to snake_case
      var translationKey = "ACCOUNT_FIELD_"+(item.key.replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace(/ /g, '_').toUpperCase()).replace(/[^A-Z_]/g, '_');

      const translatedTitle = this.translate.get(translationKey+"_TITLE");
      const translatedDescription = this.translate.get(translationKey+"_INFO");
      const translatedExample = this.translate.get(translationKey+"_EXAMPLE");
      
      const groupTranslationKey = "ACCOUNT_GROUP_"+((item.group ? item.group : 'general').replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace(/ /g, '_').toUpperCase());
      const translatedGroup = this.translate.get('ACCOUNT_GROUP_'+(item.group ? item.group : 'general').toUpperCase());

      if(translatedTitle) item.title = translatedTitle;
      if(translatedDescription) item.description = translatedDescription;
      if(translatedExample) item.example = translatedExample;
      //if(translatedGroup) item.group = translatedGroup;

      item.subtitle = item.description+(item.example ? " ("+item.example+")" : "");
    });

    return list;
  }

  public getAvailableSyncIntervals(opts: any = {}): Array<any>{
    if(!opts || !opts.asOptions) return TIME_INTERVALS;

    let intervals = TIME_INTERVALS;
    intervals.forEach((item) => {
      item.title = this.translate.get(item.title);
      delete item.subtitle;
    });

    let list = [{
      value: "",
      title: this.translate.get("INTEGRATION_SYNC_INTERVAL_MANUAL"),
      /*subtitle: this.translate.get("INTEGRATION_SYNC_INTERVAL_MANUAL_INFO"),*/
    }, ...intervals ] as any;

    return list;
  }

  public automatchFieldMapping(name: string, localOptions: Array<any>) {
    if (name == "") return "";

    let foreignName = name.toLowerCase();
    let foundKey = "";
    localOptions.forEach(opt => {
      if (opt.synonyms.indexOf(foreignName) != -1){ 
        foundKey = opt.key;
      }
    });

    return foundKey;
  }

  public calculateIntervalString(str: string, now: Date): Date {

    if(!str) return new Date();

    if(!now || now instanceof Date == false) now = new Date();

    const params = str.split(' ');
    const schedule = params[0];

    let nextDate = new Date(now);
  
    switch (schedule) {
      case 'daily':
        nextDate.setDate(now.getDate() + 1);
        break;
      case 'weekly':
        
        const weekday = params[1].substring(0, params[1].length - 1); // remove the "s"

        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const targetDay = daysOfWeek.indexOf(weekday.toLowerCase());
        const daysUntilTarget = (targetDay - now.getDay() + 7) % 7;
        nextDate.setDate(now.getDate() + daysUntilTarget);
        break;
      case 'monthly':
        let dayOfMonth = params[1];

        if(dayOfMonth == "lastDayOfMonth"){
          // determine last day of month
          nextDate.setMonth(now.getMonth() + 1);
          nextDate.setDate(0);
          dayOfMonth = nextDate.getDate().toString();
        }
        const targetMonth = now.getMonth() + 1;
        const targetYear = now.getFullYear();
        const targetDate = new Date(`${targetYear}-${targetMonth}-${dayOfMonth}`);
      
        if (targetDate.getDate() < now.getDate()) {
          nextDate.setMonth(now.getMonth() + 1);
        }
      
        nextDate.setDate(parseInt(dayOfMonth));
        break;
      default:
        throw new Error(`Invalid schedule: ${schedule}`);
    }
    
    const time = params[params.length - 1];

    const [hours, minutes] = time.split(':');
    nextDate.setHours(parseInt(hours));
    nextDate.setMinutes(parseInt(minutes));
    nextDate.setSeconds(0);

    if (nextDate <= now) {
      nextDate.setDate(nextDate.getDate() + 1);
    }
  
    return nextDate;

  }

} 
