import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  @Input('title') title: string = '';
  @Input('message') message: string = '';

  @Input('color') color: string = '';

  @Input('mimicFinapi') mimicFinapi: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) { 

   }

  ngOnInit() {
        // add class to host element if mimiFinapi is true
        if(this.mimicFinapi){
          this.renderer.addClass(this.el.nativeElement, 'mimic-finapi');
        }
  }

}
