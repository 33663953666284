import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Institution } from 'src/app/models/Institution';
import { ConnectedInstitution } from 'src/app/models/ConnectedInstitution';
import { InstitutionService } from 'src/app/providers/institution-service/institution-service';
import { ProjectService } from 'src/app/providers/project-service/project-service';
import { EventEmitter } from '@angular/core';
import { Account } from 'src/app/models/Account';
import { AlertController, ToastController } from '@ionic/angular';
import { NotificationMessage } from '../notification-message/notification-message.component';
import { TranslationBridge } from 'src/app/providers/translation-bridge/translation-bridge';

@Component({
  selector: 'institution-account-list',
  templateUrl: './institution-account-list.component.html',
  styleUrls: ['./institution-account-list.component.scss'],
})
export class InstitutionAccountListComponent implements OnInit {

  @ViewChild('popover') popover;

  accounts: Account[] = [];

  _institution: ConnectedInstitution;
  @Input() set connectedInstitution(institution: any) {
    this._institution = institution as ConnectedInstitution;
    this.accounts = this._institution.accounts;


    const accountWithError = this.accounts.find(account => account.interfaces[0].status != 'UPDATED' && account.interfaces[0].status != 'DOWNLOAD_IN_PROGRESS');
    
    this.needsUpdate = this.accounts.find(account => !account.isAuth) != null && accountWithError != null;

    const isDownloading = this.accounts.find(account => account.interfaces[0].status == 'DOWNLOAD_IN_PROGRESS' && !account.isAuth);
    const webformId = this.institutionService.getUpdateWebformFromAccounts(this.accounts);

    if(isDownloading && webformId) {
      this.messages = [{
        type: 'warning',
        icon: 'bank',
        title: this.translation.get("NOTIFICATION_OPEN_UPDATE_FORM"),
        text: this.translation.get("NOTIFICATION_OPEN_UPDATE_FORM_INFO"),
        actionTitle: this.translation.get("NOTIFICATION_OPEN_UPDATE_FORM_ACTION"),
        action: () => { 
          this.institutionService.openUpdateBankAccountModal(this._institution.id,{webformId: webformId});
        }
      }];
    }

  }

  @Output() reload = new EventEmitter<any>();


  messages: NotificationMessage[] = [];

  contextOpen: boolean = false;

  /* updating the account */
  needsUpdate: boolean = false;
  updateInProgress: boolean = false;

  checkBailout: number = 0;
  checkInterval: any;

  constructor(
    private institutionService: InstitutionService, 
    private translation: TranslationBridge,
    private projectService: ProjectService,
    private alertController: AlertController,
    private toastController: ToastController) { }

  ngOnInit() {}

  async updateConnection() {
    this.contextOpen = false;
    let projectId = this.projectService.currentProject.id;
    this.updateInProgress = true;
    this.checkBailout = 0;
    this.institutionService.updateInstitutionConnection(this._institution.id, projectId).then((res: any) => {
      this.checkInterval = setInterval(() => {
        this.checkUpdateProgres();
        this.checkBailout++;
        if(this.checkBailout > 15) {
          clearInterval(this.checkInterval);
          this.updateInProgress = false;
          this.reload.emit();
          this.toastController.create({
            message: 'Update failed. Please try again.',
            duration: 4000,
            cssClass: 'toast-error'
          });  
        }
      }, 5000);
    });
  }

  async checkUpdateProgres(){
    let projectId = this.projectService.currentProject.id;
    let res = await this.institutionService.getConnectedInstitutionById(this._institution.id,projectId);

    // @ts-ignore check if we need a webform
    let webformNeeded = res.interfaces.find(i => i.userActionRequired == true);
    if(webformNeeded){

      // @ts-ignore
      const webformId = await this.institutionService.getUpdateWebformFromAccounts(res.accounts);

      if(webformId){ 
        clearInterval(this.checkInterval);
        this.updateInProgress = false;
        this.institutionService.openConnectBankAccountModal({webformId: webformId}).then((result: any) => {
          this.reload.emit();
        });
      }
    }

  }

  async deleteInstiutionConnection() {
    this.contextOpen = false;

    let projectId = this.projectService.currentProject.id;

    let alert = await this.alertController.create({
      header: 'Delete Connection',
      message: 'Are you sure? This will delete all accounts and transactions belonging to this Connection. It will also delete the history of integration actions belonging to these accounts.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Delete',
          handler: () => {
            this.institutionService.deleteConnectedInstitution(this._institution.id, projectId).then(() => {
              this.reload.emit();
            });
          }
        }]
    });

    await alert.present();

  }

  async deleteAccountConnection(accountId) {
    this.contextOpen = false;

    let projectId = this.projectService.currentProject.id;

    let alert = await this.alertController.create({
      header: 'Delete Account',
      message: 'Are you sure? This will delete this accounts and transactions belonging to this account. It will also delete the history of integration actions belonging to this account.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Delete',
          handler: () => {
            this.institutionService.deleteConnectedAccount(accountId, projectId).then(() => {
              this.reload.emit();
            });
          }
        }]
    });

    await alert.present();

  }
  
  openContextMenu(ev: Event){
    ev.preventDefault();
    ev.stopPropagation();
    this.contextOpen = true;
    this.popover.event = {
      target: ev.target,
    };
  }
  onContextOpen(){
    this.contextOpen = true;
  }
  onContextClose(){
    this.contextOpen = false;
  }

  getBankIcon(item: ConnectedInstitution) {
    let url = "";
    if(item instanceof Institution || item.bank == null) {
      // @ts-ignore
      url = item.icon?.url;
    } else {
      url = item.bank.icon?.url;
    }
    if(!url) {
      url = "assets/images/bank-icon-placeholder.png";
    }
    return url;
  }

  getBankLogo(item: ConnectedInstitution) {
    let url = "";
    if(item instanceof Institution || item.bank == null) {
      // @ts-ignore
      url = item.logo?.url;
    } else {
      url = item.bank.logo?.url;
    }
    if(!url) {
      url = "assets/images/bank-logo-placeholder.png";
    }
    return url;
  }

  categoryIcon(category: string) {
    switch(category.toUpperCase()) {
      case 'CHECKING': return 'assets/icons/phos/bank-duotone.svg';
      case 'CREDITCARD': return 'assets/icons/phos/bank-duotone.svg';
      case 'SAVINGS': return 'assets/icons/phos/coin-duotone.svg';
      case 'SECURITY': return 'assets/icons/phos/briefcase-duotone.svg';
      case 'LOAN': return 'assets/icons/phos/handshake-duotone.svg';
      case 'MEMBERSHIP': return 'assets/icons/phos/briefcase-duotone.svg';

      default: return 'assets/icons/phos/cardholder-duotone.svg';
    }
  }

  getExpirationDate(item: ConnectedInstitution) {

    if(!item?.interfaces[0]) return null;
    if(!item.interfaces[0].aisConsent) return null;

    if(item.interfaces[0].aisConsent.expiresAt) return item.interfaces[0].aisConsent.expiresAt;
    return null;
  }


  determineInterfaceStatus(status: string){
    if(status == "UPDATED"){
      return "success";
    } 
  }

  /*
    Balances
  */
  getInstiutionBalance(item: ConnectedInstitution){
    let balance = 0;
    item.accounts.forEach((account) => {
      balance += account.balance;
    });
    return balance;
  }
  getInstiutionCurrency(item: ConnectedInstitution){
    if(item.accounts.length > 0){
      return item.accounts[0].accountCurrency;
    }
    return '';
  }

}
