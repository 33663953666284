import { Pipe, PipeTransform } from '@angular/core';
import { TranslationBridge } from '../providers/translation-bridge/translation-bridge';

@Pipe({
    name: 'interval'
})
export class intervalPipe implements PipeTransform {

  constructor(private translate: TranslationBridge) { }

  transform(encodedString: string) {

    let parts = encodedString.split(" ");
    let interval = parts[0];
    let glued = "";
    parts.forEach((part,ind) => {
      if(ind == 0 && interval == "monthly") part = "MONTHLY EACH";
      if(ind == 1 && interval == "monthly" && part != "lastDayOfMonth") part += ".";
      glued += this.translate.get(part.toString().toUpperCase()) + " ";
    });
    return glued;
  }
}