import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'iban'
})

export class ibanPipe implements PipeTransform {
  transform(str: string) {
    if (!str) {
      return false;
    }
    let after = 4;
    let c = " ";
    var v = str.replace(/[^\dA-Z]/g, ''),
        reg = new RegExp(".{" + after + "}", "g");
    return v.replace(reg, function (a) {
        return a + c;
    }).replace(/[^0-9]+$/, "");
  }
}